fragment heroFragment on Hero {
  _type
  _key
  _id
  background {
    type
    color
    size {
      mobile
      desktop
    }
    mobile {
      alt
      asset {
        url
      }
      hotspot {
        x
        y
      }
    }
    desktop {
      alt
      asset {
        url
      }
      hotspot {
        x
        y
      }
    }
    gradientMobile {
      show
      position
      intensity
    }
    gradientDesktop {
      show
      position
      intensity
    }
  }
  heroContent {
    componentRaw
    mobilePosition {
      horizontal
      vertical
    }
    desktopPosition {
      horizontal
      vertical
    }
    buttons {
      label
      link
      variant
      color
    }
  }
}
