#import "./components/SellerBreadCrumbs.fragment.gql"
#import "../components/ItemMetaData.fragment.gql"
#import "../components/carousel/carousel.fragment.gql"
#import "../components/lightbox/PDPLightBox.fragment.gql"
#import "./components/wishlistCounter.fragment.gql"
#import "./components/ClaimInfo.fragment.gql"
#import "./components/ItemChangeRequestsInfo.fragment.gql"
#import "../components/itemStores.fragment.gql"
#import "./components/EmissionSavings.fragment.gql"
#import "./components/RejectInfo.fragment.gql"
#import "./components/saleTimeLineSection.fragment.gql"
#import "./components/saleInfo/SaleInfo.fragment.gql"
#import "../components/hooks/ProductInfo.fragment.gql"
#import "../components/productInfo/SustainabilityInfo.fragment.gql"
#import "./components/actions/components/changePricing/ChangePricing.fragment.gql"
#import "./components/actions/components/changePricing/ExtendPricingPeriod.fragment.gql"

query sellerPDPQuery(
  $itemId: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  item(id: $itemId) {
    ...SellerBreadCrumbs
    ...ItemMetaData
    ...CarouselFragment
    ...ClaimInfoItemFragment
    ...ItemStoresFragment
    ...EmissionSavingsFragment
    ...RejectInfoItemFragment
    ...SaleTimeLineSectionFragment
    ...SaleInfoItemFragment
    ...PDPLightBoxFragment
    ...ProductInfoFragment
    ...SellerSustainabilityInfoFragment
    ...ChangePricingItemFragment
    ...ExtendPricingPeriodItemFragment
    itemStatus
  }

  itemClaim: fetchClaimByItem(itemId: $itemId) {
    ...ClaimInfoFragment
    ...SaleInfoClaimFragment
  }

  itemWishlistConnection: itemWishlists(
    where: {
      active: { equalTo: true }
      selected: { equalTo: true }
      item: { have: { objectId: { equalTo: $itemId } } }
    }
  ) {
    ...WishlistCounterFragment
  }

  itemChangeRequests: fetchItemChangeRequests(itemId: $itemId) {
    ...ItemChangeRequestsInfoFragment
  }

  saleRequest: getSaleRequestForItem(itemId: $itemId) {
    ...RejectInfoSaleRequestFragment
  }

  sendBackRequest: getSendBackRequestForItem(itemId: $itemId) {
    ...RejectInfoSendBackRequestFragment
  }

  ifRejectedDonateAfter: getDonateAfter(itemId: $itemId) # returns null if item is not rejected
  balanceTransactions(
    where: {
      item: { have: { objectId: { equalTo: $itemId } } },
      type: { in: [
        "itemSold",
        "itemCommission",
        "itemSortingFee",
        "itemAdFee",
        "itemClaimedAdFeeReduction",
        "itemClaimedCommissionReduction",
        "itemClaimedSoldReduction"
      ] }
    }
  ) {
    edges {
      node {
        ...SaleInfoBalanceTransactionFragment
      }
    }
  }

  marketInventory: getLatestMarketInventoryForItem(itemId: $itemId) {
    objectId
    ...ChangePricingMarketInventoryFragment
    ...ExtendPricingPeriodMarketInventoryFragment
  }
}
