import React from 'react'
import styled from 'styled-components'

import { showTrustScore } from '../../../../../common/region/config'
import PaymentOptionIcons from '../../../../components/PaymentOptionIcons.jsx'
import { useResponsive } from '../../../../hooks'
import { mediaQueries } from '../../../../uiComponents/mediaQueries'
import AppNudge from '../AppNudge.jsx'
import DeliveryInfo from './DeliveryInfo.jsx'
import TrustScore from './TrustScore.jsx'

const MidWrapper = styled.div`
  padding: 0;
  ${mediaQueries.tablet} {
    padding: 0 16px;
  }
`

const MidContainer = styled.div`
  margin-inline: auto;
  align-content: center;
  ${mediaQueries.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
`
const BadgeSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${mediaQueries.tablet} {
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 0;
  }
  ${mediaQueries.phone} {
    padding: 0;
  }
`

const EcomBadgeSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  ${mediaQueries.tablet} {
    padding: 8px 0 24px 0;
    display: ${({ showTrustScore }) => (!showTrustScore ? 'none' : 'flex')};
  }
  ${mediaQueries.phone} {
    padding: 8px 0 16px 0;
    display: ${({ showTrustScore }) => (!showTrustScore ? 'none' : 'flex')};
  }
`

const DeliveryPaymentInfo = () => {
  const { isTablet } = useResponsive()
  return (
    <MidWrapper>
      <MidContainer>
        <BadgeSection noPadding={!isTablet}>
          <AppNudge />
          {showTrustScore && (
            <EcomBadgeSection>
              <TrustScore />
            </EcomBadgeSection>
          )}
          <DeliveryInfo />
          <PaymentOptionIcons
            headerDesign={'body2'}
            showHeadline
            upperCase={false}
            iconContainerStyles={{ width: 'fit-content' }}
          />
        </BadgeSection>
      </MidContainer>
    </MidWrapper>
  )
}

export default DeliveryPaymentInfo
