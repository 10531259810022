import config from 'config'
import { createQueryPreloader } from '@apollo/client'
import { initializeApolloClient } from './client'
/** @import {PreloadQueryFunction} from "@apollo/client" */

/** @type {ReturnType<typeof initializeApolloClient> | null} */
let apolloClientInstance = null

/** @type {PreloadQueryFunction | null} */
let queryPreloader = null

export const getApolloClient = () => {
  if (!apolloClientInstance) {
    apolloClientInstance = initializeApolloClient({
      sanityConfig: config.sanity,
      apolloConfig: config.apollo
    })
  }
  return apolloClientInstance
}

/**
 * @type {PreloadQueryFunction}
 */
export const preloadQuery = (...args) => {
  if (!queryPreloader) {
    queryPreloader = createQueryPreloader(getApolloClient())
  }
  return queryPreloader(...args)
}
