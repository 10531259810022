#import "../apollo/localizedMetadata.fragment.gql"
#import "./orderClaims.fragment.gql"
#import "./components/itemClaimSummary.fragment.gql"
#import "./components/itemClaimCardList.fragment.gql"
#import "./components/claimInstructions.fragment.gql"
#import "./components/shipmentLabel.fragment.gql"

query getOrderClaims(
  $orderId: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  order(id: $orderId) {
    ...orderClaimsOrderFragment
    ...shipmentLabelOrderFragment
    ...itemClaimSummaryOrderFragment
  }
  claims: fetchOwnClaimsByOrder(orderId: $orderId) {
    ...orderClaimsClaimFragment
    ...itemClaimSummaryClaimFragment
    ...itemClaimCardListClaimFragment
    ...claimInstructionsClaimFragment
  }
  shipments: getClaimShipmentsForOrder(orderId: $orderId) {
    ...orderClaimsClaimShipmentFragment
    ...shipmentLabelClaimShipmentFragment
  }
  creditTransactions: creditTransactionRefundsMarket(id: $orderId) {
    ...itemClaimSummaryCreditTransactionFragment
  }
}
