query getUserPersonalisationValues($locale: String!) {
  getUserPersonalisationValues(locale: $locale) {
    baseCategoryScores {
      baseCategory
      score
    }
    topBrands {
      name
    }
    categoriesPerBaseCategory {
      baseCategory
      categoryObjectId
      nameLocalised
    }
    topCategories {
      baseCategory
      categoryObjectId
      nameLocalised
    }
  }
}
