fragment bannerFragment on Banner {
  _type
  _id
  headline
  body
  backgroundColor
  isMirrored
  callToAction {
    text
    link
  }
  image {
    alt
    asset {
      url
    }
    hotspot {
      x
      y
    }
  }
  sticker {
    asset {
      url
    }
  }
}
