import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Switch, P, A } from '@sellpy/design-system-react-web'
import { region as regionFunctions } from '@sellpy/commons'
import { cookieSettingsModalOpen } from '../../common/ui/actions'
import { SimpleModal } from '../uiComponents'
import { getDataUsageConsentForUser, setDataUsageConsentForUser } from '../../common/user/actions'
import {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_EXTERNAL_MARKETING
} from '../../common/user/dataHandling'

const StyledModal = styled(SimpleModal)`
  z-index: var(--cookie-settings-modal-z-index);
`

const SetCookieWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px 0;
`

const DisabledLabel = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
`

const SetCookie = ({ checked, onChange, disabled, headline, body }) => {
  const { t } = useTranslation('general')
  return (
    <>
      <SetCookieWrapper>
        <P design='h7' noMargin>
          {headline}
        </P>
        {disabled ? (
          <DisabledLabel noMargin design='body1' color='grey'>
            {t('cookieSettingsModal.cookieSettings.alwaysOn')}
          </DisabledLabel>
        ) : (
          <Switch checked={checked} onChange={onChange} />
        )}
      </SetCookieWrapper>
      <P noMargin design={'body1'}>
        {body}
      </P>
    </>
  )
}

const CookieSettingsModal = () => {
  const { t } = useTranslation('general')
  const dispatch = useDispatch()
  const open = useSelector((state) => state.ui.cookieSettingsModalOpen)
  const dataUsageConsent = useSelector((state) => state.user.dataUsageConsent)

  const activeAnalyticsConsent = Boolean(dataUsageConsent.get(PERMISSION_TYPE_ANALYTICS))
  const activeExternalMarketingConsent = Boolean(
    dataUsageConsent.get(PERMISSION_TYPE_EXTERNAL_MARKETING)
  )

  const [analyticsConsent, setAnalyticsConsent] = useState()
  const [marketingConsent, setMarketingConsent] = useState()

  useEffect(() => {
    setAnalyticsConsent(activeAnalyticsConsent)
    setMarketingConsent(activeExternalMarketingConsent)
  }, [activeAnalyticsConsent, activeExternalMarketingConsent])

  const onClose = () => {
    dispatch(getDataUsageConsentForUser(null, { cacheBust: true }))
    dispatch(cookieSettingsModalOpen(false))
  }

  const reloadIfRequired = ({ analyticsConsent, marketingConsent }) => {
    if (
      (analyticsConsent != null && Boolean(analyticsConsent) === false && activeAnalyticsConsent) ||
      (marketingConsent != null &&
        Boolean(marketingConsent) === false &&
        activeExternalMarketingConsent)
    ) {
      window.location.reload()
    }
  }

  if (analyticsConsent === undefined || marketingConsent === undefined) return null
  return (
    <StyledModal isOpen={open} onClose={onClose} headline={t('cookieSettingsModal.headline')}>
      <P design='body1'>
        <Trans
          i18nKey={'general:cookieSettingsModal.body'}
          values={{
            cookiePolicyLink: regionFunctions.cookiePolicyLink(process.env.REGION)
          }}
          components={{
            Link: <A noMargin />
          }}
        />
      </P>
      <SetCookie
        disabled={true}
        headline={t('cookieSettingsModal.cookieSettings.NecessaryCookies.headline')}
        body={t('cookieSettingsModal.cookieSettings.NecessaryCookies.body')}
      />
      <SetCookie
        checked={analyticsConsent}
        onChange={() => setAnalyticsConsent(!analyticsConsent)}
        headline={t('cookieSettingsModal.cookieSettings.analytics.headline')}
        body={t('cookieSettingsModal.cookieSettings.analytics.body')}
      />
      <SetCookie
        checked={marketingConsent}
        onChange={() => setMarketingConsent(!marketingConsent)}
        headline={t('cookieSettingsModal.cookieSettings.marketing.headline')}
        body={
          <Trans
            i18nKey={'general:cookieSettingsModal.cookieSettings.marketing.body-v2'}
            components={{ Link: <A noMargin /> }}
            values={{
              metaLink: 'https://www.facebook.com/privacy/policy/',
              tiktokLink: 'https://www.tiktok.com/legal/page/eea/privacy-policy/en',
              pinterestLink: 'https://policy.pinterest.com/en/privacy-policy',
              googleLink: 'https://business.safety.google/privacy/',
              bingLink: 'https://www.microsoft.com/en-us/privacy/privacystatement',
              snapchatLink: 'https://values.snap.com/privacy/privacy-policy'
            }}
          />
        }
      />
      <Button
        label={t('cookieSettingsModal.acceptAll')}
        color={'black'}
        fullWidth
        style={{ marginTop: '16px' }}
        onClick={() => {
          dispatch(
            setDataUsageConsentForUser({
              type: PERMISSION_TYPE_ANALYTICS,
              consent: true
            })
          )
          dispatch(
            setDataUsageConsentForUser({
              type: PERMISSION_TYPE_EXTERNAL_MARKETING,
              consent: true
            })
          )
          setAnalyticsConsent(true)
          setMarketingConsent(true)
          onClose()
        }}
      />
      <Button
        label={t('cookieSettingsModal.acceptNecessaryOnly')}
        variant='outlined'
        color={'black'}
        fullWidth
        onClick={async () => {
          await Promise.all([
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_ANALYTICS,
                consent: false
              })
            ),
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                consent: false
              })
            )
          ])
          reloadIfRequired({ analyticsConsent: false, marketingConsent: false })
          setAnalyticsConsent(false)
          setMarketingConsent(false)
          onClose()
        }}
        style={{ marginTop: 8 }}
      />
      <Button
        label={t('cookieSettingsModal.setCookieSettings')}
        color={'black'}
        fullWidth
        style={{ marginTop: '8px' }}
        variant='outlined'
        onClick={async () => {
          await Promise.all([
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_ANALYTICS,
                consent: Boolean(analyticsConsent)
              })
            ),
            dispatch(
              setDataUsageConsentForUser({
                type: PERMISSION_TYPE_EXTERNAL_MARKETING,
                consent: Boolean(marketingConsent)
              })
            )
          ])
          reloadIfRequired({ analyticsConsent, marketingConsent })
          onClose()
        }}
      />
    </StyledModal>
  )
}

export default CookieSettingsModal
