fragment SaleInfoClaimFragment on ItemClaim {
  objectId
  refundData
  reason
  paidAt
  createdAt
  images {
    ... on Element {
      value
    }
  }
}

fragment SaleInfoItemFragment on Item {
  objectId
  itemStatus
  p2p
  p2pValueShare
  paidAt
  bag {
    objectId
    currency
  }
}

fragment SaleInfoBalanceTransactionFragment on BalanceTransaction {
  type
  delta
}

fragment SaleInfoPublicOrderFragment on Order {
  status
  dateDelivered
}
