fragment itemsInOrderFragment on Order {
  createdAt
  items {
    ... on Item {
      images {
        ... on Element {
          value
        }
      }
    }
  }
}
