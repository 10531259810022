import { marketplace, locale as localeConfig } from '@sellpy/commons'
import { region } from './region'

export const locale = process.env.LOCALE_OVERRIDE || marketplace.LOCALE[region()]

export const charityPartnersLink = localeConfig.charityPartnersLink(locale)
export const whatCanISellLink = localeConfig.whatCanISellLink(locale)
export const whatCanINotSellLink = localeConfig.whatCanINotSellLink(locale)
export const creditFAQLink = localeConfig.creditFAQLink(locale)
export const adyenPrivacyPolicyLink = localeConfig.adyenPrivacyPolicyLink(locale)
export const faqLink = localeConfig.faqLink(locale)
export const localizedMetadata = localeConfig.localizedMetadata(locale)
export const segmentToBaseCategory = localeConfig.segmentToBaseCategory(locale)

export const LOCALIZED_ELASTIC_ITEM_INDEX = {
  sv: 'item',
  de: 'item_de',
  en: 'item_en',
  pl: 'item_pl',
  nl: 'item_nl',
  da: 'item_da',
  fi: 'item_fi',
  fr: 'item_fr',
  cs: 'item_cs'
}[locale]

export const LOCALIZED_ITEM_TYPE_INDEX = {
  sv: 'itemType_sv',
  de: 'itemType_de',
  en: 'itemType_en',
  pl: 'itemType_pl',
  nl: 'itemType_nl',
  da: 'itemType_da',
  fi: 'itemType_fi',
  fr: 'itemType_fr',
  cs: 'itemType_cs'
}[locale]

export const localeBooleansForGQL = (localeOverride) => ({
  isSV: (localeOverride || locale) === 'sv',
  isDE: (localeOverride || locale) === 'de',
  isEN: (localeOverride || locale) === 'en',
  isNL: (localeOverride || locale) === 'nl',
  isDA: (localeOverride || locale) === 'da',
  isPL: (localeOverride || locale) === 'pl',
  isFI: (localeOverride || locale) === 'fi',
  isFR: (localeOverride || locale) === 'fr',
  isCS: (localeOverride || locale) === 'cs'
})
