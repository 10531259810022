import { Trans, useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'
import { region as regionFunctions } from '@sellpy/commons'
import { P, A } from '@sellpy/design-system-react-web'
import { region as getRegion } from '../../common/region/region'

const Text = styled(P)`
  text-align: center;
  padding: 1rem 1rem 2rem 1rem;
`

export const CheckoutPolicies = ({ withSalePolicy, region = getRegion() }) => {
  const { t } = useTranslation('cart')
  return (
    <Text noMargin design={'body5'}>
      <Trans t={t} i18nKey='policies.prefix'>
        prefix
      </Trans>
      {regionFunctions.showCancellationPolicy(region) && (
        <Trans t={t} i18nKey='policies.cancellation'>
          <A
            href={regionFunctions.cancellationPolicyLink(region)}
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </A>
          ,
        </Trans>
      )}
      {withSalePolicy && (
        <Trans t={t} i18nKey='policies.sell'>
          <A
            href={regionFunctions.sellingPolicyLink(region)}
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </A>
          ,
        </Trans>
      )}
      <Trans t={t} i18nKey='policies.datapolicy'>
        <A href={regionFunctions.dataPolicyLink(region)} target='_blank' rel='noopener noreferrer'>
          link
        </A>
        ,
      </Trans>
      <Trans t={t} i18nKey='policies.buy'>
        <A
          href={regionFunctions.purchasePolicyLink(region)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </A>
        ,
      </Trans>
      <Trans t={t} i18nKey='policies.sufix'>
        sufix
      </Trans>
    </Text>
  )
}
