import Parse from 'parse'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { region as regionFunctions } from '@sellpy/commons'
import { gql, useQuery } from '@apollo/client'
import { A } from '@sellpy/design-system-react-web'
import { region as getRegion } from '../../../common/region/region.js'
import { Checkbox } from '../../uiComponents'
import {
  PERMISSION_TYPE_EMAIL_MARKETING,
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_LOYALTY_PROGRAM
} from '../../../common/user/dataHandling.js'
import { getDataUsageConsentForUser } from '../../../common/user/actions'
import { CheckoutPolicies } from '../../checkout/CheckoutPolicies.jsx'
import DataPolicyNotice from './DataPolicyNotice.jsx'

const policyLinks = {
  [PERMISSION_TYPE_DATA_POLICY]: regionFunctions.dataPolicyLink(process.env.REGION),
  [PERMISSION_TYPE_LOYALTY_PROGRAM]: regionFunctions.loyaltyPolicyLink(process.env.REGION)
}

const HiddenInput = styled.input`
  display: none;
`

export const getConsentLabel = ({ t, permissionType, alreadyHaveNewsletterPromocode }) => {
  const link = policyLinks[permissionType]
  return link ? (
    <Trans i18nKey={`dataUsageConsent.${permissionType}`}>
      part1
      <A noMargin target='_blank' href={link}>
        link
      </A>
    </Trans>
  ) : permissionType === PERMISSION_TYPE_EMAIL_MARKETING ? (
    t(
      `dataUsageConsent.${PERMISSION_TYPE_EMAIL_MARKETING}${
        alreadyHaveNewsletterPromocode ? 'NoPromocode' : ''
      }`
    )
  ) : (
    t(`dataUsageConsent.${permissionType}`)
  )
}

const CLOUD_GET_EMAIL_NEWSLETTERSIGNUP = gql`
  query getEmailNewsletterSignup($email: String!) {
    newsletterSignup: getEmailNewsletterSignup(email: $email) {
      objectId
    }
  }
`

const DataConsent = ({
  permissions,
  register,
  errors,
  showCheckoutPolicies,
  regionOverride = getRegion()
}) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDataUsageConsentForUser())
  }, [dispatch])
  const loading = useSelector((state) => !state.user.dataUsageConsentLoaded)
  const dataUsageConsent = useSelector((state) => state.user.dataUsageConsent)
  const user = Parse.User.current()

  const email = user?.get('email')
  const { loading: newsletterSignupLoading, data: newsletterSignupData } = useQuery(
    CLOUD_GET_EMAIL_NEWSLETTERSIGNUP,
    {
      variables: { email },
      skip: !email
    }
  )

  if (loading || newsletterSignupLoading) return null

  const permissionsToShow = permissions
    .filter(({ type }) => !dataUsageConsent.get(type))
    .filter(({ type }) => type !== PERMISSION_TYPE_DATA_POLICY)
  const consentedPermissions = permissions.filter(({ type }) => dataUsageConsent.get(type))

  const implicitlyAddDataPolicy =
    permissions.some(({ type }) => type === PERMISSION_TYPE_DATA_POLICY) &&
    !consentedPermissions.some(({ type }) => type === PERMISSION_TYPE_DATA_POLICY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
    >
      {permissionsToShow.map(({ type: permissionType, required }) => (
        <Checkbox
          {...register(`dataUsageConsent.${permissionType}`, {
            required: required ? t('dataUsageConsent.required') : null
          })}
          key={permissionType}
          label={getConsentLabel({
            t,
            permissionType,
            alreadyHaveNewsletterPromocode: Boolean(newsletterSignupData?.newsletterSignup)
          })}
          errors={errors}
        />
      ))}
      {/* We want the consented permissions to be part of the submit values
       without showing checkboxes for them */}
      {consentedPermissions.map(({ type: permissionType }) => (
        <HiddenInput
          type='checkbox'
          {...register(`dataUsageConsent.${permissionType}`, {
            value: true
          })}
          key={permissionType}
        />
      ))}
      {/* Include data policy if it's part of the permissions array, but never show it. */}
      {implicitlyAddDataPolicy && (
        <HiddenInput
          type='checkbox'
          {...register(`dataUsageConsent.${PERMISSION_TYPE_DATA_POLICY}`, {
            value: true
          })}
        />
      )}
      {showCheckoutPolicies && <CheckoutPolicies withSalePolicy region={regionOverride} />}
      {!showCheckoutPolicies && implicitlyAddDataPolicy && (
        <DataPolicyNotice region={regionOverride} />
      )}
    </div>
  )
}

DataConsent.propTypes = {
  permissions: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  variant: PropTypes.string,
  errors: PropTypes.object
}

export default DataConsent
