fragment claimedFragment on ItemClaim {
  createdAt
}

fragment claimedItemFragment on Item {
  objectId
  p2p
  itemStatus
  donatedAt
}
