#import "../../apollo/localizedMetadata.fragment.gql"

fragment itemClaimCardListClaimFragment on ItemClaim {
  objectId
  createdAt
  status
  paidAt
  sellpyMotivation
  refundData
  offer
  awaitingRefund
  item {
    objectId
    ...localizedMetadataFragment
    itemIO {
      objectId
      titleOutputOrder {
        ... on Element {
          value
        }
      }
      bodyOutputOrder {
        ... on Element {
          value
        }
      }
    }
    images {
      ... on Element {
        value
      }
    }
  }
}
