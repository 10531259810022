fragment SellerBreadCrumbs on Item {
  p2p
  bag {
    objectId
  }
  metadata
  metadata_en
  metadata_de
  metadata_nl
  metadata_da
  metadata_pl
  metadata_fi
  metadata_fr
}
