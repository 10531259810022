import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import * as cartActions from '../cart/actions'
import { currentUserSelector } from '../user/selectors'
import * as actions from './actions'
import { ENTITY_CLASSNAMES } from './base'
import { getEntity } from './selectors/base'

export const InitialState = Record({
  ...ENTITY_CLASSNAMES.reduce((map, classname) => {
    map[classname] = Map()
    return map
  }, {})
})

const reducer = (state = new InitialState(), action) => {
  switch (action.type) {
    case actions.CACHE_ENTITIES:
      return state.mergeWith(
        (oldEntities, newEntities) => oldEntities.merge(newEntities),
        action.entities
      )
    case actions.DELETE_ENTITY:
      return state.update(action.className, (entityClass) => entityClass.delete(action.id))
    // Item id is used to patch entities Item state until we disableSingleInstance in parse.
    case cartActions.REMOVE_MARKET_RESERVATION_SUCCESS:
      return state.deleteIn(['Item', action.itemId, 'reservations'])
    default:
      return state
  }
}

export default reducer

export const ownItemsSelector = createSelector(
  [getEntity('Item'), currentUserSelector],
  (items, userId) => items.filter((item) => item.get('user') === userId)
)

export const ownItemFeedback = createSelector(
  [getEntity('ItemFeedback'), getEntity('Item'), currentUserSelector],
  (itemFeedbacks, itemId, userId) =>
    itemFeedbacks.find(
      (itemFeedback) =>
        itemFeedback.get('givenBy') === userId && itemFeedback.get('item') === itemId
    )
)

export const ownBagsSelector = createSelector(
  [getEntity('Bag'), currentUserSelector],
  (bags, userId) => bags.filter((bag) => bag.get('user') === userId)
)

export const ownContainerOrderSelector = createSelector(
  [getEntity('ContainerOrder'), currentUserSelector],
  (containerOrders, userId) =>
    containerOrders
      .filter((containerOrder) => containerOrder.get('user') === userId)
      .sortBy(
        (containerOrder) => containerOrder.get('createdAt'),
        (a, b) => new Date(b) - new Date(a)
      )
)

export const ownPayOutsSelector = createSelector(
  [getEntity('PayOut'), currentUserSelector],
  (payOuts, userId) => payOuts.filter((bag) => bag.get('user') === userId)
)

export const ownReferralsSelector = createSelector(
  [getEntity('Referral'), currentUserSelector],
  (referrals, userId) =>
    referrals.filter(
      (referral) => referral.get('referrer') === userId || referral.get('referred') === userId
    )
)

export const itemClaimedSelector = createSelector(
  [getEntity('ItemClaim'), (_, item) => item, getEntity('BalanceTransaction')],
  (claims, item, balanceTransactions) =>
    claims.find(
      (claim) =>
        claim.get('item') === item.get('objectId') &&
        claim.get('type') === 'claim' &&
        (balanceTransactions.find(
          (transaction) =>
            transaction.get('type') === 'itemClaimedCommissionReduction' &&
            transaction.get('item') === item.get('objectId')
        ) ||
          item.get('itemStatus') === 'skänkt')
    )
)

export const containersBySaleSelector = createSelector([getEntity('Container')], (containers) =>
  containers.groupBy((container) => container.get('bag'))
)

export const pastPresortedSalesSelector = createSelector([getEntity('Bag')], (sales) =>
  sales.filter((sale) => sale.get('presortedAt'))
)

export const lastPresortedSaleSelector = createSelector([pastPresortedSalesSelector], (sales) =>
  sales
    .sortBy(
      (sale) => sale.get('presortedAt'),
      (a, b) => new Date(b) - new Date(a)
    )
    .first()
)

export const presortedAtSelector = (state, item) => {
  const timestamp = state.entities.Bag.getIn([item.get('bag'), 'presortedAt'])
  return timestamp ? new Date(timestamp) : undefined
}
