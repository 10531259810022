#import "../components/sanity/hero/hero.fragment.gql"
#import "../components/sanity/salesBanner/salesBanner.fragment.gql"
#import "../components/sanity/staticSlider/staticSlider.fragment.gql"
#import "../components/sanity/banner/banner.fragment.gql"
#import "../components/sanity/twoTileBanner/twoTileBanner.fragment.gql"
#import "../components/sanity/storeBanner/storeBanner.fragment.gql"
#import "../components/sanity/multiColumnChipBanner/multiColumnChipBanner.fragment.gql"
#import "../components/sanity/personalisedHero/personalisedHero.fragment.gql"
#import "../components/sanity/personalisedTwoTileBanner/personalisedTwoTileBanner.fragment.gql"
#import "../components/sanity/demographyHero/demographyHero.fragment.gql"
#import "../components/sanity/demographyTwoTileBanner/demographyTwoTileBanner.fragment.gql"

query getPreviewLandingPage($id: ID!, $isFullUser: Boolean!) {
  allLandingPage(limit: 1, sort: { abTest: { weight: DESC } }, where: { _id: { eq: $id } }) {
    contentLoggedIn @include(if: $isFullUser) {
      ...salesBannerFragment
      ...heroFragment
      ...staticSliderFragment
      ...dynamicSliderFragment
      ...bannerFragment
      ...twoTileBannerFragment
      ...storeBannerFragment
      ...multiColumnChipBannerFragment
      ...personalisedHeroFragment
      ...personalisedTwoTileBannerFragment
      ...demographyHeroFragment
      ...demographyTwoTileBannerFragment
    }
    contentNotLoggedIn @skip(if: $isFullUser) {
      ...salesBannerFragment
      ...heroFragment
      ...staticSliderFragment
      ...dynamicSliderFragment
      ...bannerFragment
      ...twoTileBannerFragment
      ...storeBannerFragment
      ...multiColumnChipBannerFragment
      ...personalisedHeroFragment
      ...personalisedTwoTileBannerFragment
      ...demographyHeroFragment
      ...demographyTwoTileBannerFragment
    }
  }
}

fragment dynamicSliderFragment on DynamicSlider {
  _type
  content
}
