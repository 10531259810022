import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { imageTools } from '@sellpy/commons'
import { captureMessage } from '@sentry/react'

const isPrerender = () => {
  const userAgent = navigator.userAgent
  const crawlerUserAgent = 'Prerender'
  return userAgent.toLowerCase().indexOf(crawlerUserAgent.toLowerCase()) !== -1
}

const HoverImage = ({
  primaryImage,
  secondaryImage,
  style,
  className,
  darken,
  altPrimary,
  altSecondary,
  ...props
}) => {
  const [hovering, setIsHovering] = useState(false)
  const imageToShow = hovering ? secondaryImage || primaryImage : primaryImage
  const altToShow = hovering ? altSecondary || altPrimary : altPrimary
  return (
    <img
      loading='lazy'
      alt={altToShow}
      src={imageTools.optimizeImage(imageToShow, { width: 400 })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={className}
      onError={(e) => {
        if (isPrerender()) return
        const { effectiveType, rtt, downlink } = navigator.connection || {}
        const networkInfo = { effectiveType, rtt, downlink }
        captureMessage(new Error('Failed to load image'), {
          level: 'error',
          fingerprint: ['{{default}}', 'HoverImage-onError'],
          extra: {
            src: e.target.src,
            alt: e.target.alt,
            page: window.location.href,
            userAgent: navigator.userAgent,
            networkInfo
          }
        })
      }}
      style={{
        display: 'block',
        width: '100%',
        height: 'auto',
        filter: darken && imageTools.isDarkenableImage(imageToShow) ? 'brightness(0.98)' : 'none',
        aspectRatio: '1/1',
        ...style
      }}
      {...props}
    />
  )
}

HoverImage.propTypes = {
  primaryImage: PropTypes.string.isRequired,
  secondaryImage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
}

export default HoverImage
