fragment RejectInfoItemFragment on Item {
  objectId
  metadata @include(if: $isSV)
  metadata_de @include(if: $isDE)
  metadata_en @include(if: $isEN)
  metadata_nl @include(if: $isNL)
  metadata_da @include(if: $isDA)
  metadata_pl @include(if: $isPL)
  metadata_fi @include(if: $isFI)
  metadata_fr @include(if: $isFR)
  metadata_cs @include(if: $isCS)
  itemStatus
  container {
    unsellableReason
  }
  rejectReason
}

fragment RejectInfoSaleRequestFragment on SaleRequest {
  returnIfRejected
  motivation
  createdAt
}

fragment RejectInfoSendBackRequestFragment on SendBackRequest {
  createdAt
}
