fragment multiColumnChipBannerFragment on MultiColumnChipBanner {
  _type
  _id
  title
  columns {
    title
    chips {
      title
      link
    }
  }
}
