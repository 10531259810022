import styled from 'styled-components'
import { FixedImage } from '@sellpy/design-system-react-web'

export const DeliveryPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  flex-wrap: wrap;
  gap: 8px;
`

export const DeliveryPaymentBadges = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
`

export const BadgeContainer = styled.div`
  width: 48px;
  padding: 0 8px;
`

export const Badge = styled(FixedImage)`
  max-height: 32px;
`
