fragment twoTileBannerFragment on TwoTileBanner {
  _type
  _id
  textIsCentered
  textColor
  slabHeader {
    headline
    body
    callToAction {
      text
      link
    }
  }
  leftImageTile {
    labelRaw
    sublabel
    link
    images {
      mobile {
        alt
        asset {
          url
        }
      }
      desktop {
        alt
        asset {
          url
        }
      }
    }
  }
  rightImageTile {
    labelRaw
    sublabel
    link
    images {
      mobile {
        alt
        asset {
          url
        }
      }
      desktop {
        alt
        asset {
          url
        }
      }
    }
  }
}
