fragment pastPayoutsFragment on PayOut {
  objectId
  status
  charityOrganization {
    objectId
    name
    key
  }
  amount
}
