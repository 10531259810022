fragment orderInfoFragment on OrdersOverview {
  _id
  order {
    objectId
    createdAt
    freight
    orderPayment
    dateDelivered
  }
  orderRating {
    objectId
  }
  freightData {
    freightStatus
    dateDelivered
  }
}
