fragment PreparedSalePricingItemFragment on Item {
  objectId
  bag {
    objectId
    saleConfig
  }
}

fragment PreparedSalePricingMarketInventoryFragment on MarketInventory {
  objectId
  createdAt
}

fragment LockedPricingMarketInventoryFragment on MarketInventory {
  endAfter
  checkValueAfter
}
