import React from 'react'
import './appPayments.css'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createRoot } from 'react-dom/client'
import config from 'config'
import { initializeI18n } from 'init-i18n'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { init as initSentry, ErrorBoundary } from '@sentry/react'
import { ApolloProvider } from '@apollo/client'
import { initializeParse } from '../market/src/client/lib/parse.js'
import { BreakpointProvider } from '../market/src/client/app/breakpointProvider.jsx'
import theme from '../market/src/client/uiComponents/mergeThemes.js'
import configureStore from '../market/src/common/configureStore.js'
import mediaQueries from '../market/src/client/uiComponents/theme.json'
import { getApolloClient } from '../market/src/client/apollo/apolloClientSingleton.js'
import PaymentProviders from './PaymentProviders.jsx'

initializeParse(config.parse)
initializeI18n({ lng: null })
const store = configureStore()

if (process.env.NODE_ENV === 'production') {
  initSentry({
    dsn: config.sentry.dsn_appPayments,
    environment: config.environment,
    release: process.env.COMMIT_HASH,
    attachStacktrace: true, // also capture stack trace when Sentry.captureMessage is called
    beforeSend: (event) => {
      try {
        // This check is only to handle "unhandled rejections" of Parse errors.
        const prefix = 'Non-Error promise rejection captured with keys'
        if (event.exception.values[0].value.startsWith(prefix)) {
          event.exception.values[0].value = JSON.stringify(event.extra.__serialized__)
          // This deletion is super-weird, but unfortunatley neccessary for Sentry to not class the event as "<unknown>".
          // There seems to be a bug where Sentry thinks that a synthetic stacktrace is included (for unhandled rejections)
          // but is in fact missing. This seems to cause the "<unknown>" classification.
          delete event.exception.values[0].mechanism.synthetic
        }
      } catch (error) {
        // ignore if something broke, and send event to sentry
      }

      try {
        // This error is due to an underlying dependency of the paypal sdk that is used through adyen.
        // The error is an informational message and should not break any other JS code on the page.
        // https://github.com/paypal/paypal-checkout-components/issues/1506
        if (
          event.exception.values[0].value.startsWith('Detected container element removed from DOM')
        ) {
          return null
        }
      } catch (error) {
        // ignore if something is undefined, and send event to sentry
      }

      return window.navigator.onLine ? event : null
    }
  })
}

const Root = () => {
  const apolloClient = getApolloClient()
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <BreakpointProvider mediaQueries={mediaQueries.mediaQueries}>
              <PaymentProviders />
            </BreakpointProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  )
}

const root = createRoot(document.getElementById('app'))
root.render(<Root />)
