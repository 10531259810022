import { money } from '@sellpy/commons'
import { Record, Map } from 'immutable'
import {
  FETCH_OFFICICAL_PRICING_MODEL_SUCCESS,
  SAVED_CO2_AND_WATER_SAVINGS_SUCCESS
} from './actions'

const baseData = {
  adFeeAmount: 0,
  customerValueShare: 0,
  adFeeFormatted: '',
  expensiveItemShareThresholdFormatted: '',
  expensiveItemShare: ''
}

const InitialState = Record({
  savedWaterAndCO2: Map(),
  pricingModel: Map({
    SEK: baseData,
    DKK: baseData,
    EUR: baseData,
    PLN: baseData,
    CZK: baseData
  })
})

export default function saleReducer(state = new InitialState(), action) {
  switch (action.type) {
    case SAVED_CO2_AND_WATER_SAVINGS_SUCCESS:
      return state.update('savedWaterAndCO2', (savedWaterAndCO2) =>
        savedWaterAndCO2.merge(action.savedWaterAndCO2)
      )
    case FETCH_OFFICICAL_PRICING_MODEL_SUCCESS: {
      const pricingModel = {
        adFeeAmount: action.pricingModel.adFee,
        adFeeFormatted: money.formatBaseUnit({
          amount: action.pricingModel.adFee,
          currency: action.currency
        }),
        expensiveItemShareThresholdFormatted: money.formatBaseUnit({
          amount: action.pricingModel.expensiveItemShare.threshold,
          currency: action.currency
        }),
        customerValueShare: 100 * action.pricingModel.customerValueShare,
        expensiveItemShare: 100 * action.pricingModel.expensiveItemShare.share
      }
      return state.setIn(['pricingModel', action.currency], pricingModel)
    }
  }
  return state
}
