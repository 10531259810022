fragment PayoutFragment on PayOut {
  objectId
  createdAt
  value
  status
  region
  bankAccount {
    objectId
  }
  charityOrganization {
    objectId
    name
  }
}

query getPayouts($region: String!, $userId: ID!, $statuses: [String]!, $after: String) {
  payOuts(
    where: {
      AND: {
        user: { have: { objectId: { equalTo: $userId } } }
        region: { equalTo: $region }
        status: { in: $statuses }
      }
    }
    first: 3
    after: $after
  ) {
    edges {
      node {
        ...PayoutFragment
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
