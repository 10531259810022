#import "../ItemMetaData.fragment.gql"

fragment ProductInfoFragment on Item {
  objectId
  headline
  bodyText
  sourceLanguage
  materialCompositions {
    ... on Element {
      value
    }
  }
  photos {
    ... on Element {
      value
    }
  }
  ...ItemMetaData
}
