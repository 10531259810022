#import "./payoutRow.fragment.gql"
#import "./pastPayouts.fragment.gql"

query withdraw($statuses: [String!], $cursor: String) {
  payOuts(order: createdAt_DESC, first: 10, where: { status: { in: $statuses } }, after: $cursor) {
    edges {
      node {
        ...payoutRowFragment
        ...pastPayoutsFragment
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

