#import "../hero/hero.fragment.gql"

fragment demographyHeroFragment on DemographyHero {
  _type
  _id
  demographyHeroList {
    default {
      ...heroFragment
    }
    women {
      ...heroFragment
    }
    men {
      ...heroFragment
    }
    kids {
      ...heroFragment
    }
    things {
      ...heroFragment
    }
  }
}
