import { gql } from '@apollo/client'
import { getApolloClient } from '../apollo/apolloClientSingleton'
import { debounceAsync } from '../../common/lib/asyncLodash'

const VALIDATE_TIN = gql`
  query validateTin($tin: String!, $countryCode: String!) {
    validateTin(tin: $tin, countryCode: $countryCode)
  }
`

/**
 * @param {{ tin: string, countryCode: string }} params
 * @param {string} invalidTinMessage
 * @param {string} apiErrorMessage
 */
export const validateTin = async ({ tin, countryCode }, invalidTinMessage, apiErrorMessage) => {
  if (!tin || !countryCode) return true

  const { data, errors } = await getApolloClient().query({
    query: VALIDATE_TIN,
    variables: {
      tin,
      countryCode
    }
  })

  if (errors?.length) return apiErrorMessage

  const { valid } = data?.validateTin || {}

  return valid || invalidTinMessage
}

export const debounceAsyncValidateTin = debounceAsync(validateTin, 800)
