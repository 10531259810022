fragment ItemStoresFragment on Item {
  objectId
  user {
    objectId
  }
  featuredIn {
    ... on Element {
      value
    }
  }
}
