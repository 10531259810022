import { DateTime } from 'luxon'
import { money, marketplace } from '@sellpy/commons'
import Parse from 'parse'
import i18n from 'i18next'
import {
  analyticsPurchaseV2,
  analyticsItemPricingChargeCompleted,
  analyticsSendBackRequestCheckoutConfirmed,
  analyticsGiftCardPurchase,
  analyticsLoyaltyPeriodPurchase
} from '../analytics/actions'
import { cacheEntities } from '../entities/actions'
import { ownFreshReservations } from '../entities/selectors/reservations'
import { getAmount } from '../../client/checkout/tools'
import { showToast } from '../ui/actions'
import { TOAST_TYPE } from '../../client/uiComponents/notifications/Toasts.jsx'
import { getItemsInCart } from '../entities/selectors/checkout'
import { region, validShippingCountries } from '../region/region'
import { locale } from '../region/locale'
import { analyticsItemMapper } from '../analytics/mappers'
import { CHECKOUT_TYPES } from '../../client/checkout/analytics'
import { signupAnonymous } from '../user/actions.js'
import { getApolloClient } from '../../client/apollo/apolloClientSingleton.js'

export const advancePayment = ({ navigate }) => (paymentId) => async (dispatch) => {
  const payment = await getPayment(paymentId)
  payment.get('adyenSessionId') &&
    payment.get('reservations') &&
    Parse.Cloud.run('setReservationToProcessing', {
      adyenSessionId: payment.get('adyenSessionId')
    }).then((reservations) => {
      dispatch(cacheEntities(reservations))
    })

  analyticsForPaymentComplete(payment, dispatch, true)
  dispatch(syncReservations())
  navigate(`/payment/${payment.id}`, { replace: true })
}

const getPayment = (id) => {
  return Parse.Cloud.run('pollPaymentWithSessionIdOrPaymentId', { id })
}

const reportAnalyticsGiftCardPurchase = (paymentId) => {
  return async (dispatch) => {
    const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
    dispatch(cacheEntities(payment))
    dispatch(cacheEntities(entities))
    analyticsGiftCardPurchase({
      totalPayment: getAmount(payment),
      paymentId: payment.id
    })
  }
}

const reportAnalyticsSellerItemPricingPeriodPurchase = (paymentId) => () => {
  return async (dispatch) => {
    const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
    dispatch(cacheEntities(payment))
    dispatch(cacheEntities(entities))
    analyticsItemPricingChargeCompleted({
      pricing: getAmount(payment)
    })
  }
}

const reportAnalyticsSendBackRequestPurchase = (paymentId) => () => {
  return async (dispatch) => {
    const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
    dispatch(cacheEntities(payment))
    dispatch(cacheEntities(entities))
    analyticsSendBackRequestCheckoutConfirmed(payment)
  }
}

const reportAnalyticsLoyaltyPeriodPurchase = (paymentId) => () => {
  return async (dispatch) => {
    const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
    dispatch(cacheEntities(payment))
    dispatch(cacheEntities(entities))
    analyticsLoyaltyPeriodPurchase(payment)
  }
}

export const reportAnalyticsCheckoutV2Purchase = async (paymentId) => {
  const [{ payment, entities }, marketOrderCount, latestPurchaseDate] = await Promise.all([
    Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId }),
    Parse.Cloud.run('marketPurchaseCount', { paymentId }),
    Parse.Cloud.run('latestPurchaseDate')
  ])
  const itemReservations = entities.filter((order) => order.className === 'ItemReservation')
  const itemIds = itemReservations.map((itemReservation) => itemReservation.get('item').id)
  const itemCategories = await Parse.Cloud.run('itemCategoriesFromItems', { itemIds, locale })
  const currency = payment.get('adyenAmount').currency
  const promoCode = payment.get('promoCode')?.get('code')
  const extractedCategories = Object.fromEntries(
    Object.entries(itemCategories).map(([key, cats]) => {
      if (cats === null) {
        cats = {}
      }
      return [key, Object.values(cats)[Object.values(cats).length - 1][0]]
    })
  )

  const lineItemData =
    payment.get('type') === 'Bag'
      ? payment.get('metadata')?.lineItemsData
      : payment
          .get('metadata')
          ?.ordersData.map((order) => order.lineItemsData)
          .reduce((previousValue, currentValue) => {
            return [...previousValue, ...currentValue]
          })

  if (!lineItemData || !lineItemData.length) {
    return
  }

  const { shippingAmount, cartAmount, items, containerCount } = lineItemData.reduce(
    (acc, item) => {
      if (item.type === 'FREIGHT') {
        acc.shippingAmount += item.amountIncludingTax.amount
      } else if (item.type === 'ITEM') {
        acc.cartAmount += item.amountIncludingTax.amount
        acc.items[item?.pointers?.Item] = item.amountIncludingTax
      } else if (item.type === 'CONTAINER') {
        acc.cartAmount += item.amountIncludingTax.amount
        acc.items['CONTAINER'] = item.amountIncludingTax
        acc.containerCount++
      }
      return acc
    },
    { shippingAmount: 0, cartAmount: 0, items: {}, containerCount: 0 }
  )

  const analyticsItems = itemReservations.map((itemReservation) => {
    const item = itemReservation.get('item')
    return analyticsItemMapper({
      objectID: item.id,
      metadata: item.get(`metadata${locale === 'sv' ? '' : `_${locale}`}`),
      [`price_${region()}`]: items[item.id],
      categories: { lvl0: [extractedCategories[item.id]] }
    })
  })

  if (items['CONTAINER']) {
    analyticsItems.push(
      analyticsItemMapper({
        objectID: 'CONTAINER',
        [`price_${region()}`]: items['CONTAINER'],
        categories: { lvl0: ['Container'] },
        quantity: containerCount
      })
    )
  }

  analyticsPurchaseV2({
    checkoutType: CHECKOUT_TYPES.CART,
    currency,
    value: money.toBaseUnit({ currency, amount: cartAmount }).amount,
    ...(promoCode && { coupon: promoCode }),
    shipping: money.toBaseUnit({ currency, amount: shippingAmount }).amount,
    marketOrderCount,
    latestPurchaseDate,
    purchaseType: payment.get('type') === 'Item' ? 'marketPurchase' : undefined,
    paymentId: payment.id,
    itemIds,
    items: analyticsItems
  })
}

export const GET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'GET_DEFAULT_PAYMENT_METHOD_SUCCESS'
export const GET_DEFAULT_PAYMENT_METHOD_ERROR = 'GET_DEFAULT_PAYMENT_METHOD_ERROR'

export const getAdyenPaymentMethods = (country, region) =>
  Parse.Cloud.run('getAdyenPaymentMethods', {
    region,
    country: country || validShippingCountries[0]
  })

export const disableAdyenUserSavedPaymentMethods = () =>
  Parse.Cloud.run('disableAdyenUserSavedPaymentMethods', { region: process.env.REGION })

export const GET_CART_CONTENT_LOADING = 'GET_CART_CONTENT_LOADING'
export const GET_CART_CONTENT_SUCCESS = 'GET_CART_CONTENT_SUCCESS'
export const GET_CART_CONTENT_ERROR = 'GET_CART_CONTENT_ERROR'

export const getCartContent = () => {
  return async (dispatch, getState) => {
    if (getState().cart.cartContentLoading) return
    dispatch({ type: GET_CART_CONTENT_LOADING })
    try {
      await dispatch(syncReservations())
      dispatch({ type: GET_CART_CONTENT_SUCCESS })
    } catch (error) {
      console.error(error)
      dispatch({ type: GET_CART_CONTENT_ERROR })
    }
    return getItemsInCart(getState())
  }
}

export const SYNC_RESERVATIONS_LOADING = 'SYNC_RESERVATIONS_LOADING'
export const SYNC_RESERVATIONS_SUCCESS = 'SYNC_RESERVATIONS_SUCCESS'
export const SYNC_RESERVATIONS_ERROR = 'SYNC_RESERVATIONS_ERROR'

let syncReservationsPromise

export const syncReservations = (reservationIdsToRestore = []) => {
  return async (dispatch, getState) => {
    if (getState().cart.syncReservationsLoading) return syncReservationsPromise
    dispatch({ type: SYNC_RESERVATIONS_LOADING })

    const ownFreshReservationIds = ownFreshReservations(getState())
      .keySeq()
      .toArray()

    // when working with reservations, fall back to anonymous user similar to how we do when manually adding to cart
    if (!Parse.User.current()) {
      await dispatch(signupAnonymous())
    }
    syncReservationsPromise = Parse.Cloud.run('syncReservations', {
      reservationIds: ownFreshReservationIds.concat(reservationIdsToRestore),
      region: process.env.REGION
    })
      .then(async (reservations) => {
        if (reservations.some(isSellerReturnReservation)) {
          await getPricingModelsForSellerReturns({ reservations, dispatch })
        }
        return reservations
      })
      .then((reservations) => {
        handleReservationTimeouts({ reservations, dispatch })
        dispatch(cacheEntities(reservations))
        dispatch({ type: SYNC_RESERVATIONS_SUCCESS })
        dispatch({ type: ADDING_TO_CART_RESOLVED })
        return reservations
      })
      .catch((error) => {
        dispatch({ type: SYNC_RESERVATIONS_ERROR })
        throw error
      })
    return syncReservationsPromise
  }
}

const getPricingModelsForSellerReturns = async ({ reservations, dispatch }) => {
  for (const sellerReturnReservation of reservations.filter(isSellerReturnReservation)) {
    const sale = sellerReturnReservation.get('item').get('bag')
    const pricingModel = await Parse.Cloud.run('getPricingModelDetailsForSale', {
      saleId: sale.id
    })
    dispatch(cachePricingModel({ sale, pricingModel }))
  }
}

const isSellerReturnReservation = (reservation) => reservation.get('type') === 'sellerReturn'

let timeout = null

const handleReservationTimeouts = ({ reservations, dispatch }) => {
  const oldestReservation = reservations
    .filter((reservation) => reservation.get('type') === 'market')
    .filter((reservation) => reservation.get('status') === 'fresh')
    .sort((a, b) => new Date(a.get('updatedAt')) - new Date(b.get('updatedAt')))
    .pop()

  if (oldestReservation) {
    const timeoutDuration = Math.max(
      DateTime.local()
        .plus({ minutes: 30 })
        .diff(DateTime.fromJSDate(oldestReservation.get('updatedAt'))).milliseconds,
      0
    )

    timeout && clearTimeout(timeout)

    timeout = setTimeout(
      () =>
        dispatch(
          showToast({
            header: i18n.t('cart:timeOutToast.header'),
            body: i18n.t('cart:timeOutToast.body'),
            type: TOAST_TYPE.INFO
          })
        ),
      timeoutDuration
    )
  } else {
    timeout = timeout && clearTimeout(timeout)
  }
}

export const ADDING_TO_CART_PENDING = 'ADDING_TO_CART_PENDING'
export const ADDING_TO_CART_RESOLVED = 'ADDING_TO_CART_RESOLVED'

export const CREATE_MARKET_RESERVATION_LOADING = 'CREATE_MARKET_RESERVATION_LOADING'
export const CREATE_MARKET_RESERVATION_SUCCESS = 'CREATE_MARKET_RESERVATION_SUCCESS'

export const createMarketReservation = ({ itemId }) => {
  return (dispatch) => {
    dispatch({ type: CREATE_MARKET_RESERVATION_LOADING })
    return Parse.Cloud.run('createMarketReservation', {
      itemId,
      region: process.env.REGION
    }).then((reservation) => {
      dispatch(cacheEntities(reservation))
      dispatch({ type: CREATE_MARKET_RESERVATION_SUCCESS })
      dispatch(syncReservations())
    })
  }
}

export const REMOVE_MARKET_RESERVATION_LOADING = 'REMOVE_MARKET_RESERVATION_LOADING'
export const REMOVE_MARKET_RESERVATION_SUCCESS = 'REMOVE_MARKET_RESERVATION_SUCCESS'

export const removeMarketReservation = (reservationId) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_MARKET_RESERVATION_LOADING })
    return Parse.Cloud.run('removeMarketReservation', { reservationId })
      .then((reservation) => {
        dispatch(cacheEntities(reservation))
        // Item id is sent to patch entities Item state until we disableSingleInstance in parse.
        dispatch({
          type: REMOVE_MARKET_RESERVATION_SUCCESS,
          itemId: reservation.get('item').id
        })
        dispatch(syncReservations())

        // 👇 when reservations are mutated with apollo as well, we should refetch when executing the mutation
        // for now we do it "manually" here in the action
        const apolloClient = getApolloClient()
        apolloClient.refetchQueries({
          include: ['getItemsPreviouslyInCart']
        })
      })
      .catch(console.error)
  }
}

export const SET_PRICING_MODEL_FOR_SELLER_RETURN = 'SET_PRICING_MODEL_FOR_SELLER_RETURN'

export const cachePricingModel = ({ sale, pricingModel }) => {
  return (dispatch) =>
    dispatch({
      type: SET_PRICING_MODEL_FOR_SELLER_RETURN,
      pricingModel: {
        [sale.get('currency')]: {
          [sale.get('pricingModel')]: pricingModel
        }
      }
    })
}

export const validateAndFetchPromoCode = ({ promoCode, itemIds, nrOfBags, phone }) => {
  return (dispatch) => {
    return Parse.Cloud.run('validatePromoCode', {
      promoCode,
      itemIds,
      nrOfBags,
      phone
    }).then((promoCode) => dispatch(cacheEntities(promoCode)))
  }
}

export const CART_SET_CREDIT = 'CART_SET_CREDIT'

export const fetchUserCredits = (region) => (dispatch) => {
  return Parse.Cloud.run('currentUserCredit', {
    currency: marketplace.CURRENCY[region]
  }).then((credit) =>
    dispatch({
      type: CART_SET_CREDIT,
      credit
    })
  )
}

const analyticsForPaymentComplete = async (payment, dispatch, v2Purchase = false) => {
  const paymentId = payment.id || payment.get('objectId')
  try {
    await new Parse.Object('AnalyticsUniqueness').save({
      uniquenessId: `Purchase-Payment:${paymentId}`
    })
    if (payment.get('type') === 'Item') {
      reportAnalyticsCheckoutV2Purchase(paymentId)
    } else if (payment.get('type') === 'Bag') {
      reportAnalyticsCheckoutV2Purchase(paymentId)
    } else if (payment.get('type') === 'GiftCard') {
      dispatch(reportAnalyticsGiftCardPurchase(paymentId))
    } else if (payment.get('type') === 'SellerItemPricing') {
      dispatch(reportAnalyticsSellerItemPricingPeriodPurchase(paymentId))
    } else if (payment.get('type') === 'SendBackRequest') {
      dispatch(reportAnalyticsSendBackRequestPurchase(paymentId))
    } else if (payment.get('type') === 'LoyaltyProgramPeriod') {
      dispatch(reportAnalyticsLoyaltyPeriodPurchase(paymentId))
    }
  } catch (error) {
    if (error.code !== Parse.Error.DUPLICATE_VALUE) throw error
  }
}
