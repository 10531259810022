import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { H3, RouterLink, ResponsiveImage, P } from '@sellpy/design-system-react-web'
import LazyLoad from '../../../components/LazyLoad.jsx'
import { mediaQueries } from '../../mediaQueries.js'

export const Label = styled(H3)`
  position: ${({ $textPlacement }) => $textPlacement === 'center' && 'absolute'};
  top: ${({ $textPlacement }) => $textPlacement === 'center' && '50%'};
  transform: ${({ $textPlacement }) => $textPlacement === 'center' && 'translateY(-50%)'};
  max-width: ${({ $textPlacement }) => $textPlacement !== 'center' && '90%'};
  width: 100%;
  text-align: ${({ $textPlacement }) => ($textPlacement === 'center' ? 'center' : 'left')};
  margin-top: ${({ $textPlacement }) => $textPlacement !== 'center' && '0.5rem'};
  white-space: pre-wrap;
  color: ${({ theme, color }) => color || theme.color.grey.shade2};
  ${mediaQueries.desktop} {
    font-size: ${({ $textPlacement }) => $textPlacement === 'center' && '2vw'};
  }
  ${mediaQueries.tablet} {
    font-size: ${({ $textPlacement }) => $textPlacement === 'center' && '3vw'};
  }
  ${mediaQueries.phone} {
    font-size: ${({ $textPlacement }) => $textPlacement === 'center' && '4vw'};
  }
`

const Sublabel = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
`

const Wrapper = styled.div`
  display: flex;
`

const LinkContainer = styled(RouterLink)`
  flex-grow: 1;
  position: relative;
  transition: opacity 0.2s ease-out;
  line-height: 0;
  ${mediaQueries.largerThanPhone} {
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
`

const Overlay = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
`

const ImageTile = ({
  imageUrl,
  overlayUrl,
  imgAlt,
  overlayAlt,
  onClick,
  label,
  sublabel,
  to,
  style,
  textPlacement,
  className,
  imageOptions,
  aspectRatio = 125,
  textColor,
  mobileWidth,
  desktopWidth,
  ...props
}) => {
  return (
    <Wrapper className={className}>
      <LinkContainer onClick={onClick} to={to} style={{ width: '100%', ...style }}>
        <LazyLoad threshold={300} fallbackStyle={props.fallbackStyle} childId={imageUrl}>
          {imageUrl ? (
            <div style={{ position: 'relative' }}>
              <ResponsiveImage
                src={imageUrl}
                style={{
                  objectFit: 'cover',
                  objectPosition: 'top',
                  aspectRatio: `${100 / aspectRatio}`
                }}
                options={imageOptions}
                alt={imgAlt}
                mobileWidth={mobileWidth || '40vw'}
                desktopWidth={desktopWidth || '20vw'}
              />
              {overlayUrl && <Overlay src={overlayUrl} alt={overlayAlt} />}
            </div>
          ) : (
            <div style={{ aspectRatio: `${100 / aspectRatio}` }} />
          )}
        </LazyLoad>
        <Label
          noMargin
          design={textPlacement === 'center' ? 'h3' : 'body2'}
          $textPlacement={textPlacement}
          color={textColor}
          center
        >
          {label}
        </Label>
        {sublabel && (
          <Sublabel noMargin design='body4'>
            {sublabel}
          </Sublabel>
        )}
      </LinkContainer>
    </Wrapper>
  )
}

ImageTile.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  trackClick: PropTypes.func,
  style: PropTypes.object,
  textPlacement: PropTypes.oneOf(['center']),
  textColor: PropTypes.string
}
export default ImageTile
