#import "../../apollo/localizedMetadata.fragment.gql"

fragment itemClaimHeaderFragment on Item {
  objectId
  itemIO {
    objectId
    titleOutputOrder {
      ... on Element {
        value
      }
    }
    bodyOutputOrder {
      ... on Element {
        value
      }
    }
  }
  photos {
    ... on Element {
      value
    }
  }
  ...localizedMetadataFragment
}
