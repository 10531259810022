export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

export const showToast = (options = {}) => (dispatch) => {
  const now = Date.now()
  dispatch({
    payload: {
      key: now,
      options
    },
    type: SHOW_TOAST
  })
  setTimeout(() => dispatch(hideToast(now)), 7000)
}

export const hideToast = (key) => {
  return {
    payload: key,
    type: HIDE_TOAST
  }
}

export const ADD_FILTER = 'ADD_FILTER'
export const addFilter = (filter, value) => ({
  type: ADD_FILTER,
  filter,
  value
})

export const REMOVE_FILTER = 'REMOVE_FILTER'
export const removeFilter = (filter, value) => ({
  type: REMOVE_FILTER,
  filter,
  value
})

export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const clearFilters = (filter) => ({
  type: CLEAR_FILTERS,
  filter
})

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS
})

export const SET_NUMBER_OF_P2P_HITS_SHOWN = 'SET_NUMBER_OF_P2P_HITS_SHOWN'
export const setNumberOfP2pHitsShown = (numberOfHitsShown) => ({
  type: SET_NUMBER_OF_P2P_HITS_SHOWN,
  numberOfHitsShown: numberOfHitsShown
})

export const NEW_P2P_SEARCH = 'NEW_P2P_SEARCH'
export const newP2pSearch = (numberOfHitsShown, lastSearchUrl) => ({
  type: NEW_P2P_SEARCH,
  numberOfHitsShown: numberOfHitsShown,
  lastSearchUrl: lastSearchUrl
})

export const SET_COOKIE_SETTINGS_MODAL_OPEN = 'SET_COOKIE_SETTINGS_MODAL_OPEN'
export const cookieSettingsModalOpen = (value) => ({
  type: SET_COOKIE_SETTINGS_MODAL_OPEN,
  value: value
})
