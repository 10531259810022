#import "./components/orderInfo/orderInfo.fragment.gql"
#import "./components/itemsInOrder/itemsInOrder.fragment.gql"
#import "./components/rateAndAmount/rateAndAmount.fragment.gql"
#import "./components/trackingInfo/trackingInfo.fragment.gql"

query getOrdersOverview($cursor: String) {
  getOrdersOverview(first: 10, after: $cursor) {
    edges {
      node {
        ...orderInfoFragment
        ...amountFragment
        order {
          ...itemsInOrderFragment
          ...trackingInfoFragment
        }
        orderRating {
          ...ratingFragment
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
