fragment amountFragment on OrdersOverview {
  _id
  order {
    objectId
    freightPayment
    orderPayment
  }
  freightData {
    dateDelivered
    freightStatus
  }
}

fragment ratingFragment on OrderRating {
  objectId
  score
}
