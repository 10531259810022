import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import styled from 'styled-components'
import {
  Button,
  ResponsiveImage,
  H1,
  Subtitle,
  P,
  RouterLink,
  Grid
} from '@sellpy/design-system-react-web'
import LazyLoad from '../../components/LazyLoad.jsx'
import { mediaQueries } from '../mediaQueries.js'
const { staticImageAssets } = config

const ImageWrapper = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1;
`

const Banner = ({
  preHeadline,
  headline,
  body,
  textWrapperStyle,
  buttonText,
  link,
  imagePath,
  stickers,
  mirrored,
  color = 'white',
  ratio,
  centered,
  onClick
}) => {
  return (
    <RouterLink to={link} style={{ textDecoration: 'none', lineHeight: 0 }} onClick={onClick}>
      <Grid>
        <ImageWrapper>
          <LazyLoad threshold={300}>
            <ResponsiveImage
              src={staticImageAssets + imagePath}
              mobileWidth='100vw'
              style={{ aspectRatio: `${100 / ratio}` }}
            />
          </LazyLoad>
        </ImageWrapper>

        <Info
          color={color}
          preHeadline={preHeadline}
          headline={headline}
          textWrapperStyle={textWrapperStyle}
          body={body}
          cta={buttonText}
          stickers={stickers}
          mirrored={mirrored}
          centered={centered}
        />
      </Grid>
    </RouterLink>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row: 1;
  padding: 0 1rem;
  ${({ mirrored, centered }) =>
    centered
      ? 'grid-column: 1 / span 12;'
      : mirrored
      ? `
    grid-column: 7 / span 5;
    ${mediaQueries.tablet} {
      grid-column: 2/ span 10;
    }
  `
      : `
    grid-column: 2 / span 5;
    ${mediaQueries.tablet} {
      grid-column: 2 / span 10;
    }
  `}
`

const Info = ({
  preHeadline,
  headline,
  body,
  cta,
  centered,
  mirrored,
  stickers,
  color,
  textWrapperStyle
}) => {
  return (
    <Wrapper style={textWrapperStyle} centered={centered} mirrored={mirrored}>
      {preHeadline && (
        <Subtitle center color={color}>
          {preHeadline}
        </Subtitle>
      )}
      {headline && (
        <H1 center noMargin={!body} stickers={stickers} color={color}>
          {headline}
        </H1>
      )}
      {body && (
        <P design='body1' noMargin center color={color}>
          {body}
        </P>
      )}
      {cta && <Button style={{ marginTop: body && '3rem' }} label={cta} color={color} />}
    </Wrapper>
  )
}

export default Banner

Banner.propTypes = {
  preHeadline: PropTypes.string,
  headline: PropTypes.string.isRequired,
  body: PropTypes.string,
  stickers: PropTypes.array,
  buttonText: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  mirrored: PropTypes.bool,
  centered: PropTypes.bool,
  ratio: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func
}
