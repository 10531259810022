/* global IntersectionObserver */
import 'intersection-observer'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { alreadyLoaded } from './lazyLoadGlobalState'

class LazyLoad extends Component {
  constructor(props) {
    super(props)
    this.state = { visible: false }
    this.childId = this.props.children.props?.src || this.props.childId

    this.setElement = (element) => {
      this.element = element
    }
    this.handleIntersect = (entries) => {
      const { isIntersecting } = entries[0]
      if (!isIntersecting) return

      this.childId && alreadyLoaded.add(this.childId)
      this.setState({ visible: true })
      this.observer.disconnect()
    }
  }

  componentWillUnmount() {
    this.observer && this.observer.disconnect()
  }

  componentDidMount() {
    if (this.state.visible || alreadyLoaded.has(this.childId)) return

    const { rootId, threshold = 0 } = this.props
    this.observer = new IntersectionObserver(this.handleIntersect, {
      root: rootId && document.querySelector('#scrollContainer'),
      rootMargin: `${threshold}px`
    })
    this.observer.observe(this.element)
  }

  render() {
    return this.state.visible || alreadyLoaded.has(this.childId) ? (
      this.props.children
    ) : (
      <div
        ref={this.setElement}
        style={{
          position: this.props.inResponsiveRectangle ? 'absolute' : 'static',
          width: '100%',
          height: '100%',
          ...this.props.fallbackStyle
        }}
      />
    )
  }
}

LazyLoad.propTypes = {
  children: PropTypes.element.isRequired,
  rootId: PropTypes.string,
  threshold: PropTypes.number,
  inResponsiveRectangle: PropTypes.bool
}

export default LazyLoad
