query getAllFavorites {
  itemWishlists(
    where: { selected: { equalTo: true }, active: { equalTo: true } }
    first: 1000
    order: createdAt_DESC
  ) {
    edges {
      node {
        createdAt
        selected
        active
        objectId
        item {
          objectId
        }
        itemFavoriteLists {
          ... on ItemFavoriteList {
            objectId
          }
        }
      }
    }
  }
}
