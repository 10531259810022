import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ICONS, Icon, Button, P, H2 } from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SimpleModal } from '../uiComponents'
import { hideLoggedOutFavoriteModal } from '../../common/favorites/actions'
import { appendNextPath } from '../routing/url'

const DescriptionText = styled(P)`
  margin-bottom: 36px;
`

const StyledBenefits = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-bottom: 36px;
`

const StyledBenefitContainer = styled.div`
  width: 100%;
  min-height: 100px;

  background-color: ${({ theme }) => theme.color.blue.shade10};
  padding: 32px 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const BenefitIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 30px;

  color: ${({ theme }) => theme.color.blue.shade1};
  background-color: white;

  font-size: 22px;
  line-height: 60px;
  text-align: center;
`

const BenefitLabel = styled(P)`
  text-align: center;
  color: ${({ theme }) => theme.color.blue.shade1};
`

const LoggedOutFavoriteModal = () => {
  const { t } = useTranslation('favorite')
  const showLoggedOutFavoritesModal = useSelector(
    (state) => state.favorites.loggedOutFavoriteModal.showModal
  )
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <SimpleModal
      headline={t('logInNudgeModal.headline')}
      isOpen={showLoggedOutFavoritesModal}
      onClose={() => {
        dispatch(hideLoggedOutFavoriteModal())
      }}
    >
      <DescriptionText design='body1'>{t('logInNudgeModal.body')}</DescriptionText>
      <H2 design='h7'>{t('logInNudgeModal.benefitsHeadine')}</H2>
      <StyledBenefits>
        <StyledBenefitContainer>
          <BenefitIcon name={ICONS.ARROW_DOWN} />
          <BenefitLabel design='body4'>{t('logInNudgeModal.benefits.priceDrop')}</BenefitLabel>
        </StyledBenefitContainer>
        <StyledBenefitContainer>
          <BenefitIcon name={ICONS.THUMBS_UP} />
          <BenefitLabel design='body4'>{t('logInNudgeModal.benefits.similar')}</BenefitLabel>
        </StyledBenefitContainer>
      </StyledBenefits>
      <Button
        label={t('logInNudgeModal.logInButton')}
        fullWidth
        onClick={() => {
          dispatch(hideLoggedOutFavoriteModal())
          navigate(appendNextPath('/login', `${location.pathname}${location.search}`))
        }}
        style={{ marginBottom: 16 }}
      />
      <Button
        label={t('logInNudgeModal.createAccount')}
        variant='outlined'
        fullWidth
        onClick={() => {
          dispatch(hideLoggedOutFavoriteModal())
          navigate(appendNextPath('/signup', `${location.pathname}${location.search}`))
        }}
      />
    </SimpleModal>
  )
}

export default LoggedOutFavoriteModal
