import React from 'react'
import { AppStore, GooglePlay, A } from '@sellpy/design-system-react-web'
import styled from 'styled-components'

const AppBadges = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
  a {
    display: flex;
  }
  flex-wrap: wrap;
`

const AppNudge = () => {
  return (
    <AppBadges>
      <A
        noMargin
        target='_blank'
        rel='noopener noreferrer'
        href={`https://apps.apple.com/app/sellpy-shop-second-hand/id1594599102`}
      >
        <AppStore />
      </A>
      <A
        noMargin
        target='_blank'
        rel='noopener noreferrer'
        href={`http://play.google.com/store/apps/details?id=com.sellpy.sellpy`}
      >
        <GooglePlay />
      </A>
    </AppBadges>
  )
}

export default AppNudge
