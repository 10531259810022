import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './app/reducer'

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
  const store = composeEnhancers(applyMiddleware(thunk))(createStore)(rootReducer)

  return store
}
