#import "../components/sanity/hero/hero.fragment.gql"
#import "../components/sanity/salesBanner/salesBanner.fragment.gql"
#import "../components/sanity/staticSlider/staticSlider.fragment.gql"
#import "../components/sanity/banner/banner.fragment.gql"
#import "../components/sanity/twoTileBanner/twoTileBanner.fragment.gql"
#import "../components/sanity/storeBanner/storeBanner.fragment.gql"
#import "../components/sanity/multiColumnChipBanner/multiColumnChipBanner.fragment.gql"
#import "../components/sanity/demographyHero/demographyHero.fragment.gql"
#import "../components/sanity/demographyTwoTileBanner/demographyTwoTileBanner.fragment.gql"
#import "../components/sanity/personalisedHero/personalisedHero.fragment.gql"
#import "../components/sanity/personalisedTwoTileBanner/personalisedTwoTileBanner.fragment.gql"

query getLandingPage(
  $segment: String!
  $region: String!
  $isFullUser: Boolean!
  $clientAbTestGroup: Float
) {
  allLandingPage(
    limit: 1
    sort: { abTest: { weight: DESC } }
    where: {
      segment: { eq: $segment }
      region: { eq: $region }
      abTest: { abMin: { lte: $clientAbTestGroup }, abMax: { gte: $clientAbTestGroup } }
    }
  ) {
    contentLoggedIn @include(if: $isFullUser) {
      ...salesBannerFragment
      ...heroFragment
      ...staticSliderFragment
      ...dynamicSliderFragment
      ...bannerFragment
      ...twoTileBannerFragment
      ...storeBannerFragment
      ...multiColumnChipBannerFragment
      ...demographyHeroFragment
      ...demographyTwoTileBannerFragment
      ...personalisedHeroFragment
      ...personalisedTwoTileBannerFragment
    }
    contentNotLoggedIn @skip(if: $isFullUser) {
      ...salesBannerFragment
      ...heroFragment
      ...staticSliderFragment
      ...dynamicSliderFragment
      ...bannerFragment
      ...twoTileBannerFragment
      ...storeBannerFragment
      ...multiColumnChipBannerFragment
      ...demographyHeroFragment
      ...demographyTwoTileBannerFragment
      ...personalisedHeroFragment
      ...personalisedTwoTileBannerFragment
    }
  }
}

fragment dynamicSliderFragment on DynamicSlider {
  _type
  content
}
