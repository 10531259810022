fragment salesBannerFragment on SalesBanner {
  _type
  _id
  backgroundColor
  headerTextColor
  saleHeaderText
  saleSubHeaderText
  callToAction {
    text
    link
  }
}
