#import "../../apollo/localizedMetadata.fragment.gql"

fragment itemClaimSummaryClaimFragment on ItemClaim {
  objectId
  refundData
  paidAt
  refunded
  offer
  item {
    objectId
    ...localizedMetadataFragment
    itemIO {
      objectId
      titleOutputOrder {
        ... on Element {
          value
        }
      }
      bodyOutputOrder {
        ... on Element {
          value
        }
      }
    }
  }
}

fragment itemClaimSummaryOrderFragment on Order {
  payment {
    ... on Payment {
      adyenPaymentMethod
    }
  }
}

fragment itemClaimSummaryCreditTransactionFragment on CreditTransaction {
  objectId
  delta
  claim {
    objectId
  }
}