import Parse from 'parse'

export const AB_TESTS = {
  saveFreight: 'saveFreight',
  hideSimiliarItemsNudge: 'hideSimiliarItemsNudge',
  useLastPaymentMethod: 'useLastPaymentMethod',
  emailSpellCheckInCheckout: 'emailSpellCheckInCheckout',
  myShoppingLink: 'myShoppingLink'
}

const abTests = {
  [AB_TESTS.saveFreight]: {
    min: 0.61,
    max: 0.7,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  [AB_TESTS.useLastPaymentMethod]: {
    min: 0.21,
    max: 0.3,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  horizontalCategories: {
    min: 0.25,
    max: 0.75,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  },
  hideFreeFreightInfoboxPDP: {
    min: 0.2,
    max: 0.4,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  },
  returnRateNudge: {
    min: 0.1,
    max: 0.2,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  [AB_TESTS.hideSimiliarItemsNudge]: {
    min: 0.8,
    max: 0.9,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['user']
  },
  [AB_TESTS.emailSpellCheckInCheckout]: {
    min: 0.71,
    max: 0.8,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  },
  [AB_TESTS.myShoppingLink]: {
    min: 0.7,
    max: 1,
    regions: ['SE', 'DE', 'NL', 'AT', 'DK', 'FI', 'FR', 'EU', 'BE', 'PL'],
    fractions: ['client']
  }
}

export const setAbTestFraction = () => {
  getABTestFraction()
}

export const getABTestFraction = () => {
  if (!window.localStorage.getItem('sellpyAbTest')) {
    window.localStorage.setItem('sellpyAbTest', Math.random())
  }
  return window.localStorage.getItem('sellpyAbTest')
}

export const inAbTestTestGroup = (testName) => _inTestGroup(testName)

export const activeAbTests = () => Object.keys(abTests).filter(_inTestGroup)

const _inTestGroup = (testName) => {
  const abTest = abTests[testName]
  if (!abTest) return false
  const fractions = {
    user: Parse.User.current() && Parse.User.current().get('userAbTestFraction'),
    client: getABTestFraction()
  }
  const fraction = abTest.fractions
    .map((fractionId) => fractions[fractionId])
    .filter((fraction) => fraction != null)[0]
  return (
    abTest.min <= fraction &&
    abTest.max > fraction &&
    (abTest.regions || []).includes(process.env.REGION)
  )
}
