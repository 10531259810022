fragment ItemChangeRequestsInfoFragment on ItemChangeRequest {
  objectId
  createdAt
  approved
  response
  message
  type
  requestedValue
  itemDescriptionChange {
    reason
    originalDescription
    suggestedDescription
  }
  itemRephotography {
    reason
  }
}
