import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'
import useResponsive from '../../hooks/useResponsive.js'
import BottomInfo from './components/BottomInfo.jsx'
import DeliveryPaymentInfo from './components/deliveryPaymentInfo/DeliveryPaymentInfo.jsx'
import MainInfo from './components/mainInfo/MainInfo.jsx'
import { SocialMediaInfo } from './components/SocialMediaInfo.jsx'

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade10};
  ${mediaQueries.largerThanTablet} {
    padding: 0 32px;
  }
`

const InnerContainer = styled.div`
  // max width + padding on each side
  max-width: calc(1632px - 32px - 32px);
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Separator = styled.div`
  min-width: 100%;
  max-width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey.shade8};
`

const Footer = () => {
  const { isTablet } = useResponsive()
  return (
    <Container>
      <InnerContainer>
        <SocialMediaInfo />
        {!isTablet && <Separator />}
        <MainInfo />
        {!isTablet && <Separator />}
        <DeliveryPaymentInfo />
        <Separator />
        <BottomInfo />
      </InnerContainer>
    </Container>
  )
}

export default Footer
