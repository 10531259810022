import { captureException } from '@sentry/react'
import config from 'config'
import { initializeI18n } from 'init-i18n'
import Parse from 'parse'
import { redirect } from 'react-router-dom'
import { setAbTestFraction } from '../../common/analytics/abTest.js'
import { initFeatureToggle } from '../featureToggle'
import { ensureLocalStorage } from '../lib/ensureLocalStorage.js'
import { initializeFacebookAsync } from '../lib/facebook'
import { tagManager } from '../lib/googleTagManager'
import { initializeParse } from '../lib/parse.js'
// import { initializeTrustbadge } from '../lib/trustbadge'
import { externalReviewService, showTrustScore } from '../../common/region/region.js'
import { initializeMicroTrustScore } from '../lib/microTrustScore'
import { initializeTrustProfile } from '../lib/trustProfile.js'

const initializeTrustScore = () => {
  if (showTrustScore) {
    ;({
      // TrustedShops: initializeTrustbadge,
      TrustPilot: initializeMicroTrustScore,
      TrustProfile: initializeTrustProfile
    }[externalReviewService]())
  }
}

const handleInitializations = () => {
  ensureLocalStorage()
  initializeParse(config.parse)
  initializeI18n()
  setAbTestFraction()
  initFeatureToggle()
  initializeFacebookAsync(config.facebookAppId)
  initializeTrustScore()
  if (!window.location.href.includes('authToken')) {
    tagManager.initialize({
      gtmId: config.googleTagManagerId,
      dataLayerName: 'gtmDataLayer'
    })
  }
}

const passwordlessHook = async ({ request }) => {
  const url = new URL(request.url)
  const authToken = url.searchParams.get('authToken')
  if (!authToken) return
  try {
    await Parse.Cloud.run('currentUser')
  } catch (e) {
    if (e.code === Parse.Error.INVALID_SESSION_TOKEN) {
      await Parse.User.logOut()
    }
  }
  try {
    const sessionToken = await Parse.Cloud.run('passwordless_login', { token: authToken })
    if (sessionToken) {
      await Parse.User.become(sessionToken)
      return
    }
  } catch (error) {
    captureException(error)
    return redirect('/login')
  }
}

/**
 * Default data strategy based on the example in the docs
 * https://reactrouter.com/en/main/routers/create-browser-router#example-use-cases
 */
const defaultDataStrategy = async ({ matches }) => {
  // Grab only the matches we need to run handlers for
  const matchesToLoad = matches.filter((m) => m.shouldLoad)
  // Run the handlers in parallel
  const results = await Promise.all(
    matchesToLoad.map(async (match) => {
      // Don't override anything - just resolve route.lazy + call loader
      const result = await match.resolve()
      return result
    })
  )
  // Aggregate the results into a bn object of `routeId -> DataStrategyResult`
  return results.reduce(
    (acc, result, i) =>
      Object.assign(acc, {
        [matchesToLoad[i].route.id]: result
      }),
    {}
  )
}

let isInitialized = false

export const dataStrategy = async ({ request, matches }) => {
  if (!isInitialized) {
    handleInitializations()
    isInitialized = true
    await passwordlessHook({ request })
  }
  return defaultDataStrategy({ matches })
}
