import debounce from 'lodash/debounce'

/**
 * @description Wrapper for lodash.debounce.
 *
 * lodash.debounce does not support using async / await by default, thereby we have to wrap the function in a Promise.
 * @link More info: https://github.com/sellpy/sellphone/pull/816
 * @link Lodash issue: https://github.com/lodash/lodash/issues/4815 (closed but unresolved)
 * @template Return
 * @param {(...args: any[]) => Promise<Return>} func Function to debounce
 * @param {number} wait Debounce wait time in ms
 * @param {{leading: boolean; trailing: boolean}} [options]  Debounce options
 * @returns {(...args: any[]) => Promise<Return>} Debounced function
 */
export const debounceAsync = (func, wait, options) => {
  if (!func) throw new Error('No function passed to debounceAsync')

  const debounced = debounce(
    (resolve, reject, args) => {
      /* Wrap function in Promise.resolve to ensure not crashing if func is sync. */
      Promise.resolve(func(...args))
        .then(resolve)
        .catch(reject)
    },
    wait,
    options
  )

  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args)
    })
}
