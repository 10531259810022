#import "./components/claimed.fragment.gql"
#import "./components/saleCompleted.fragment.gql"
fragment saleTimeLineItemFragment on Item {
  objectId
  p2pValueShare
  p2p
  itemStatus
  bag {
    objectId
    pricingModel
    currency
    saleConfig
  }
  ...claimedItemFragment
  ...saleCompletedItemFragment
}
