import { createSelector } from 'reselect'
import { ITEM_STATUS } from '@sellpy/commons'
import { Map } from 'immutable'
import { getEntity } from '../entities/selectors/base'
import { currentUserSelector } from '../user/selectors'

export const ItemValuationSelector = createSelector(
  [(_, itemId) => itemId, getEntity('ItemValuation')],
  (itemId, itemValuations) =>
    itemValuations.find((itemValuation) => itemValuation.get('item') === itemId)
)

export const saleRequestForItemSelector = createSelector(
  [(_, itemId) => itemId, getEntity('SaleRequest')],
  (itemId, saleRequests) => saleRequests.find((saleRequest) => saleRequest.get('item') === itemId)
)

export const itemChangeRequestsForItemSelector = createSelector(
  [(_, itemId) => itemId, getEntity('ItemChangeRequest')],
  (itemId, itemChangeRequests) =>
    itemChangeRequests.filter((changeRequest) => changeRequest.get('item') === itemId)
)

export const itemDescriptionChangesForItemSelector = createSelector(
  [(_, itemId) => itemId, getEntity('ItemDescriptionChange')],
  (itemId, itemDescriptionChange) =>
    itemDescriptionChange.filter((descriptionChange) => descriptionChange.get('item') === itemId)
)

export const itemRephotographysForItemSelector = createSelector(
  [(_, itemId) => itemId, getEntity('ItemRephotography')],
  (itemId, itemRephotography) =>
    itemRephotography.filter((rephotography) => rephotography.get('item') === itemId)
)

export const numberOfItemsAwaitingApproval = createSelector(
  [getEntity('Item'), currentUserSelector],
  (items, userId) =>
    items
      .filter((item) => item.get('itemStatus') === ITEM_STATUS.AWAITING_APPROVAL)
      .filter((item) => item.get('user') === userId).size
)

export const itemValuesSelector = (itemIds) => (state) =>
  itemIds.reduce((map, itemId) => map.set(itemId, state.items.itemValues.get(itemId)), Map())

export const itemPaymentsSelector = (itemIds) => (state) =>
  itemIds.reduce((map, itemId) => map.set(itemId, state.items.itemPayments.get(itemId)), Map())

export const resultsInSyncWithItemEntitiesSelector = createSelector(
  [(_, elasticResults) => elasticResults, getEntity('Item')],
  (elasticResults, items) =>
    elasticResults.map((elasticResult) =>
      items.get(elasticResult.get('objectId'))
        ? elasticResult.set(
            'itemStatus',
            items.getIn([elasticResult.get('objectId'), 'itemStatus'])
          )
        : elasticResult
    )
)

export const sendBackRequestForItemIdSelector = createSelector(
  [getEntity('SendBackRequest'), (_, itemId) => itemId],
  (sendBackRequests, itemId) =>
    sendBackRequests.find((sendBackRequest) => sendBackRequest.get('item') === itemId)
)
