import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { A, H3, Facebook, Instagram, LinkedIn, Tiktok } from '@sellpy/design-system-react-web'
import { useResponsive } from '../../../hooks'
import LazyLoad from '../../../components/LazyLoad.jsx'
import SellpyLogo from '../../../components/ui/icons/SellpyLogo.jsx'
import { mediaQueries } from '../../../uiComponents/mediaQueries'

const SocialMediaGrid = styled.div`
  grid-column: span 3;
  text-align: left;
  ${mediaQueries.desktop} {
    grid-column: span 12;
  }
  ${mediaQueries.tablet} {
    grid-column: span 2;
  }
`
const SocialMediaContainer = styled.div`
  padding: 8px 16px 0;
  gap: 24px;
  display: flex;
  flex-flow: column nowrap;
  ${mediaQueries.largerThanTablet} {
    padding: 24px 0 0;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`
const SocialMediaIcons = styled(A)`
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SocialMediaLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
`

export const SocialMediaInfo = () => {
  const { isMobile } = useResponsive()
  const { t } = useTranslation('footer')
  const theme = useTheme()
  return (
    <SocialMediaContainer>
      <SellpyLogo
        color={theme.color.grey.shade2}
        style={{ width: isMobile ? 104 : 144, height: isMobile ? 32 : 44, margin: 0 }}
      />
      <SocialMediaGrid>
        <H3 design='body2' style={{ marginTop: '0' }}>
          {t('followSellpy.header')}
        </H3>
        <SocialMediaLinksWrapper>
          <SocialMediaIcons
            href={t('followSellpy.instagramLink')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LazyLoad>
              <Instagram />
            </LazyLoad>
          </SocialMediaIcons>
          <SocialMediaIcons
            href={t('followSellpy.facebookLink')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LazyLoad>
              <Facebook height={24} />
            </LazyLoad>
          </SocialMediaIcons>
          <SocialMediaIcons
            href={t('followSellpy.tiktokLink')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LazyLoad>
              <Tiktok />
            </LazyLoad>
          </SocialMediaIcons>
          <SocialMediaIcons
            href={t('followSellpy.linkedInLink')}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LazyLoad>
              <LinkedIn />
            </LazyLoad>
          </SocialMediaIcons>
        </SocialMediaLinksWrapper>
      </SocialMediaGrid>
    </SocialMediaContainer>
  )
}
