fragment orderClaimsClaimFragment on ItemClaim {
  objectId
  type
}

fragment orderClaimsClaimShipmentFragment on ClaimShipment {
  objectId
}

fragment orderClaimsOrderFragment on Order {
  objectId
  region
  toAddress
  itemPayments {
    ... on Element {
      value
    }
  }
  payment {
    ... on Payment {
      creditAmount
      adyenPaymentMethod
      adyenAmount
      stripeAmount
      metadata
    }
  }
}