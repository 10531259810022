import {
  A,
  Accordion,
  P,
  PrimaryAccordionHeader,
  PrimaryAccordionPanel,
  RouterLink
} from '@sellpy/design-system-react-web'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { useResponsive } from '../../../../hooks'
import colorTheme from '../../../../uiComponents/color/theme.json'
import {
  analyticsBagOrderButton,
  analyticsOrderPickupButton
} from '../../../../../common/analytics/actions'
import { mediaQueries } from '../../../../uiComponents/mediaQueries'
import getColumnInfo from './columnInfo.js'

const MainContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const FooterColumn = styled.div`
  :not(:last-child) div {
    border-bottom: none;
  }
  grid-column: span 2;
  text-align: left;
  ${mediaQueries.largerThanPhone} {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }
`

const ColumnsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
  width: fit-content;
`

const textStyle = css`
  color: ${({ theme }) => theme.color.grey.shade2};
  cursor: pointer;
  display: flex;
`

const StyledRouterLink = styled(RouterLink)`
  ${textStyle}
`

const StyledP = styled(P)`
  ${textStyle}
  &:hover {
    text-decoration: underline;
  }
`

const StyledA = styled(A)`
  ${textStyle}
  text-decoration: none;
`

const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`

const ContentComponent = (subItem, index) => {
  if (subItem.action) {
    return (
      <StyledP design='body3' noMargin onClick={subItem.action} key={`${subItem.text}${index}`}>
        {subItem.text}
      </StyledP>
    )
  }
  if (subItem.link.type === 'internal') {
    const analyticsEvents = {
      '/order-bag': () => analyticsBagOrderButton({ location: 'Footer' }),
      '/orderPickup': () => analyticsOrderPickupButton({ location: 'global_footer' })
    }

    const onClick = analyticsEvents[subItem.link.value] || undefined

    return (
      <StyledRouterLink
        design='body3'
        noMargin
        key={`${subItem.link.value}${index}`}
        to={subItem.link.value}
        onClick={onClick}
      >
        {subItem.text}
      </StyledRouterLink>
    )
  }
  return (
    <StyledA
      design='body3'
      noMargin
      key={`${subItem.link.value}${index}`}
      href={subItem.link.value}
      target='_blank'
      rel='noopener noreferrer'
    >
      {subItem.text}
    </StyledA>
  )
}

const MainInfo = () => {
  const dispatch = useDispatch()
  const { isTablet } = useResponsive()
  const { t } = useTranslation('footer')
  const columnInfo = useMemo(() => getColumnInfo(t, dispatch), [t, dispatch])
  return (
    <MainContainer>
      {isTablet ? (
        columnInfo.map((item, index) => (
          <Accordion
            key={item.header || index}
            id={'footer-part-' + index}
            headerComponent={<PrimaryAccordionHeader label={item.header} />}
            lastIndex={index === columnInfo.length - 1}
          >
            <PrimaryAccordionPanel>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}
              >
                {item.content.map(ContentComponent)}
              </div>
            </PrimaryAccordionPanel>
          </Accordion>
        ))
      ) : (
        <ColumnsContainer>
          {columnInfo.map((item, index) => (
            <FooterColumn key={item.header || index}>
              <P noMargin design='body2' style={{ color: colorTheme.grey.shade2 }}>
                {item.header}
              </P>
              <ContentContainer>{item.content.map(ContentComponent)}</ContentContainer>
            </FooterColumn>
          ))}
        </ColumnsContainer>
      )}
    </MainContainer>
  )
}

export default MainInfo
