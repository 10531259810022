fragment localizedMetadataFragment on Item {
  metadata @include(if: $isSV)
  metadata_de @include(if: $isDE)
  metadata_en @include(if: $isEN)
  metadata_nl @include(if: $isNL)
  metadata_da @include(if: $isDA)
  metadata_pl @include(if: $isPL)
  metadata_fi @include(if: $isFI)
  metadata_fr @include(if: $isFR)
  metadata_cs @include(if: $isCS)
}
