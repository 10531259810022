#import "../twoTileBanner/twoTileBanner.fragment.gql"

fragment demographyTwoTileBannerFragment on DemographyTwoTileBanner {
  _type
  _id
  demographyTwoTileBannerList {
    default {
      ...twoTileBannerFragment
    }
    women {
      ...twoTileBannerFragment
    }
    men {
      ...twoTileBannerFragment
    }
    kids {
      ...twoTileBannerFragment
    }
    things {
      ...twoTileBannerFragment
    }
  }
}
