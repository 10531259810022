fragment storeBannerFragment on StoreBanner {
  _type
  _id
  headline
  body
  ctaText
  imageDesktop {
    alt
    asset {
      url
    }
    hotspot {
      x
      y
    }
  }
  imageMobile {
    alt
    asset {
      url
    }
    hotspot {
      x
      y
    }
  }
  store {
    ... on CuratedStore {
      _type
      urlSlug {
        current
      }
      curations {
        _id
      }
    }
    ... on CollectionStore {
      _type
      urlSlug {
        current
      }
      _id
    }
    ... on SelectionStore {
      _type
      urlSlug {
        current
      }
      filterUrl
    }
    ... on UserStore {
      _type
      urlSlug {
        current
      }
      owner
    }
  }
}
