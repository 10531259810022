fragment shipmentLabelClaimShipmentFragment on ClaimShipment {
  objectId
  createdAt
  freightCompany
  trackingId
  type
}

fragment shipmentLabelOrderFragment on Order {
  objectId
  toAddress
}