export const NEXT_PATH_KEY = 'next-path'

export const appendNextPath = (path, nextPath) => {
  const [pathname, search] = path.split('?')
  const newSearch = new URLSearchParams(search)
  newSearch.set(NEXT_PATH_KEY, encodeURIComponent(nextPath))
  return `${pathname}${newSearch.size ? `?${newSearch.toString()}` : ''}`
}

export const extractNextPathFromLocation = (location) => {
  const encodedNextPath = location.search && new URLSearchParams(location.search).get(NEXT_PATH_KEY)
  if (encodedNextPath) return decodeURIComponent(encodedNextPath)
  return location.state?.nextLocation
}
