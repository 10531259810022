#import "../hero/hero.fragment.gql"

fragment personalisedHeroFragment on PersonalisedHero {
  _type
  _id
  personalisedHeroList {
    default {
      ...heroFragment
    }
    women {
      ...heroFragment
    }
    men {
      ...heroFragment
    }
    children {
      ...heroFragment
    }
    things {
      ...heroFragment
    }
  }
}
