#import "../components/carousel/carousel.fragment.gql"
#import "../components/EngagementSliders.fragment.gql"
#import "../components/hooks/ProductInfo.fragment.gql"
#import "../components/hooks/SustainabilityData.fragment.gql"
#import "../components/ItemMetaData.fragment.gql"
#import "../components/itemStores.fragment.gql"
#import "../components/lightbox/PDPLightBox.fragment.gql"
#import "./components/buyerActions/buyerActions.fragment.gql"
#import "./components/optionalInfo/ShippingInfo.fragment.gql"
#import "./TrackItemView.fragment.gql"

query buyerPDPQuery(
  $itemId: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  item: getPdpItem(itemId: $itemId) {
    ...BuyerActionsItem
    ...CarouselFragment
    ...EngagementSlidersFragment
    ...ItemMetaData
    ...ItemStoresFragment
    ...PDPLightBoxFragment
    ...ProductInfoFragment
    ...ShippingInfoFragment
    ...SustainabilityDataFragment
    ...TrackItemViewFragment
  }
  isFirstTimeMarketCustomer
}
