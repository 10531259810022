fragment staticSliderFragment on StaticSlider {
  _type
  _key
  _id
  textIsCentered
  textColor
  header {
    headline
    body
    callToAction {
      text
      link
    }
  }
  items {
    ... on StaticSliderItem {
      labelRaw
      link
      image {
        alt
        asset {
          url
        }
      }
    }
    ... on CuratedStore {
      title
      image {
        alt
        asset {
          url
        }
      }
      urlSlug {
        current
      }
      subtitle
      imageOverlay {
        alt
        asset {
          url
        }
      }
      segments
    }
    ... on UserStore {
      title
      image {
        alt
        asset {
          url
        }
      }
      urlSlug {
        current
      }
      segments
    }
    ... on SelectionStore {
      title
      image {
        alt
        asset {
          url
        }
      }
      urlSlug {
        current
      }
      subtitle
      imageOverlay {
        alt
        asset {
          url
        }
      }
      segments
    }
    ... on CollectionStore {
      title
      image {
        alt
        asset {
          url
        }
      }
      urlSlug {
        current
      }
      subtitle
      imageOverlay {
        alt
        asset {
          url
        }
      }
      segments
    }
  }
}
