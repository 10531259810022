fragment trackingInfoFragment on Order {
  freight
  status
  orderPackagingInfo {
    ... on Element {
      value
    }
  }
  toAddress
}
