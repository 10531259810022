#import "../apollo/localizedMetadata.fragment.gql"

fragment SaleShareReservedBalanceTransactionFragment on ReservedBalanceTransaction {
  objectId
  delta
  item {
    objectId
    ...localizedMetadataFragment
  }
}

fragment SaleShareBalanceTransactionFragment on BalanceTransaction {
  objectId
  delta
  type
  createdAt
  item {
    objectId
    itemStatus
    ...localizedMetadataFragment
  }
  payout {
    objectId
    createdAt
    bankAccount {
      objectId
    }
    charityOrganization {
      objectId
      name
    }
  }
}
