fragment BuyerActionsItem on Item {
  objectId
  p2p
  itemStatus
  user {
    objectId
  }
  reservations {
    ... on Element {
      value
    }
  }
}
