import {
  isFeatureEnabled,
  FEATURE_SELL,
  FEATURE_P2P,
  FEATURE_SELL_SIGNUP,
  FEATURE_BAG_PICKUP
} from '../../../../featureToggle'
import { cookieSettingsModalOpen } from '../../../../../common/ui/actions'

const columns = (dispatch, t) => [
  {
    header: t('sellWithSellpy.header'),
    feature: FEATURE_SELL,
    content: [
      {
        link: {
          value: '/order-bag',
          type: 'internal'
        },
        text: t('sellWithSellpy.orderBags')
      },
      {
        link: {
          value: '/orderPickup',
          type: 'internal'
        },
        text: t('sellWithSellpy.orderPickup'),
        feature: FEATURE_BAG_PICKUP
      },
      {
        link: {
          value: '/howItWorks',
          type: 'internal'
        },
        text: t('sellWithSellpy.howItWorks')
      },
      {
        link: {
          value: '/pricingInfo',
          type: 'internal'
        },
        text: t('sellWithSellpy.whatIsCost')
      },
      {
        link: {
          value: '/whatWeSell',
          type: 'internal'
        },
        text: t('sellWithSellpy.whatWeSell')
      },
      {
        link: {
          value: '/business',
          type: 'internal'
        },
        text: t('sellWithSellpy.companySales')
      },
      {
        link: {
          value: '/circle',
          type: 'internal'
        },
        text: t('sellWithSellpy.p2p'),
        feature: FEATURE_P2P
      }
    ]
  },
  {
    header: t('discover.header'),
    content: [
      {
        link: {
          value: '/',
          type: 'internal'
        },
        text: t('discover.startBuyingToday')
      },
      {
        link: {
          value: t('discover.returnLink'),
          type: 'external'
        },
        text: t('discover.return')
      },
      {
        link: {
          value: t('discover.claimLink'),
          type: 'external'
        },
        text: t('discover.claim')
      },
      {
        link: {
          value: t('discover.deliveryInformationLink'),
          type: 'external'
        },
        text: t('discover.deliveryInformation')
      },
      {
        link: {
          value: '/newsletter',
          type: 'internal'
        },
        text: t('discover.newsletter')
      }
    ]
  },
  {
    header: t('customerService.header'),
    content: [
      {
        link: {
          value: t('customerService.qAndALink'),
          type: 'external'
        },
        text: t('customerService.qAndA')
      },
      {
        link: {
          value: t('customerService.boughtItemLink'),
          type: 'external'
        },
        text: t('customerService.boughtItem')
      },
      {
        link: {
          value: t('customerService.sellWithSellpyLink'),
          type: 'external'
        },
        text: t('customerService.sellWithSellpy'),
        feature: FEATURE_SELL
      },
      {
        link: {
          value: '/sell-signup',
          type: 'internal'
        },
        text: t('customerService.sellSignup'),
        feature: FEATURE_SELL_SIGNUP
      },
      {
        action: () => dispatch(cookieSettingsModalOpen(true)),
        text: t('customerService.cookies')
      },
      {
        link: {
          value: '/support',
          type: 'internal'
        },
        text: t('customerService.contactUs')
      }
    ]
  },
  {
    header: t('getToKnowUs.header'),
    content: [
      {
        link: {
          value: '/about',
          type: 'internal'
        },
        text: t('getToKnowUs.about')
      },
      {
        link: {
          value: '/press',
          type: 'internal'
        },
        text: t('getToKnowUs.press')
      },
      {
        link: {
          value: 'https://career.sellpy.se',
          type: 'external'
        },
        text: t('getToKnowUs.workWithUs')
      },
      {
        link: {
          value: '/sustainability',
          type: 'internal'
        },
        text: t('getToKnowUs.sustainability')
      }
    ]
  }
]

const filterByFeature = (arr) => arr.filter((item) => isFeatureEnabled(item.feature))

const getColumnInfo = (t, dispatch) =>
  filterByFeature(columns(dispatch, t)).map((column) => ({
    ...column,
    content: filterByFeature(column.content)
  }))

export default getColumnInfo
