#import "../apollo/localizedMetadata.fragment.gql"

fragment claimFormFragment on ItemClaim {
  objectId
  images {
    ... on Element {
      value
    }
  }
  reason
  subReason
  motivation
  sellpyMotivation
  item {
    objectId
    ...localizedMetadataFragment
    paidAt
  }
}
