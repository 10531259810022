import { Map, List } from 'immutable'
import { currency as currencyUtil, ITEM_STATUS, itemState, money } from '@sellpy/commons'
import { staticImageAssets } from 'config'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime, Duration } from 'luxon'
import { gql, useQuery } from '@apollo/client'
import { localizedMetadata } from '../../common/region/config'
import { getItemValue } from '../../common/items/actions'
import { zeroMoney } from '../payOuts/utils'
import cache from '../apollo/cache.js'
import { itemStateMap } from './itemStatus'

export const useAlgoliaItem = ({ itemId }) =>
  useSelector((state) => state.items.algoliaItems.get(itemId))

export const useAlgoliaPrice = ({ itemId }) => {
  const algoliaItem = useAlgoliaItem({ itemId })
  const priceObject =
    algoliaItem && money.toBaseUnit(algoliaItem.get(`price_${process.env.REGION}`).toJS())

  return priceObject || zeroMoney()
}

export const useSellerItemValue = (itemId) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getItemValue(itemId))
  }, [dispatch, itemId])
  const itemValues = useSelector((state) => state.items.itemValues)
  return itemValues.get(itemId)
}

const SELLER_ITEM_VALUE_QUERY = gql`
  query getSellerItemValue($itemId: ID!) {
    getItemValues(itemIds: [$itemId])
  }
`
export const useSellerItemValueGql = (itemId) => {
  const { data, loading } = useQuery(SELLER_ITEM_VALUE_QUERY, {
    variables: {
      itemId
    }
  })
  if (loading) return null
  return data?.getItemValues && data.getItemValues[itemId]
}

export const evictItemValueCache = (itemId) => {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'getItemValues',
    args: {
      itemIds: [itemId]
    }
  })
  cache.gc()
}

export const evictItemLastPricingPeriodCache = (itemId) => {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'getLastPricingPeriodForItem',
    args: {
      itemId: itemId
    }
  })
  cache.gc()
}

export const evictItemCacheForChangeRequestForm = (itemId) => {
  cache.evict({
    id: `Item:${itemId}`,
    fieldName: 'itemStatus'
  })

  cache.evict({
    id: `ROOT_QUERY`,
    fieldName: 'fetchItemChangeRequests',
    args: {
      itemId: itemId
    }
  })

  cache.gc()
}

export const isOwnerOfItem = (item, userId) => item && userId && item.get('user') === userId

export const EMPTY_METADATA_ALLOWED_SINCE = new Date('2020-01-01')

export const isViewable = ({ item, isOwner }) => {
  if (!item) return false
  const metadata = item[localizedMetadata]
  const hasType = Boolean(metadata?.type)
  const itemStatus = item.itemStatus
  const _forSeller = hasType || new Date(item.createdAt) < EMPTY_METADATA_ALLOWED_SINCE
  const _forBuyer =
    hasType &&
    itemState.sellable({ itemStatus }) &&
    !itemState.pendingSale({ itemStatus }) &&
    !itemState.removed({ itemStatus })

  return isOwner ? _forSeller : _forBuyer
}

const defaultPhoto = (itemStatus) => {
  return { url: defaultImage(itemStatus) }
}

const defaultImage = (itemStatus) =>
  ({
    donated: `${staticImageAssets}sellerInterface/saleItems/april2021/donate-to-charity.png`,
    pendingReevaluation: `${staticImageAssets}sellerInterface/saleItems/april2021/sale-request.png`,
    sellerReturned: `${staticImageAssets}sellerInterface/saleItems/april2021/sent-back-to-seller.jpg`,
    rejected: `${staticImageAssets}sellerInterface/saleItems/april2021/rejected-items.jpg`
  }[itemStateMap[itemStatus]] ||
  `${staticImageAssets}sellerInterface/saleItems/april2021/in-progress.png`)

export const defaultSellerPhoto = (itemStatus) => {
  return { url: defaultSellerImage(itemStatus) }
}

const defaultSellerImage = (itemStatus) => {
  switch (itemStatus) {
    case ITEM_STATUS.UTSORTERAD:
      return `${staticImageAssets}sellerInterface/saleItems/october24/sortedForRecycling.png`

    case ITEM_STATUS.TILLBAKASKICKAD_DIREKT:
      return `${staticImageAssets}sellerInterface/saleItems/october24/returned.png`

    case ITEM_STATUS.OMBEDÖMNING_BEGÄRD:
      return `${staticImageAssets}sellerInterface/saleItems/october24/reevaluation.png`

    case ITEM_STATUS.REGISTRERAD:
    case ITEM_STATUS.VILANDE:
      return `${staticImageAssets}sellerInterface/saleItems/october24/bagProcessing.png`

    case ITEM_STATUS.SKÄNKT:
      return `${staticImageAssets}sellerInterface/saleItems/october24/donated.png`

    case ITEM_STATUS.SKÄNKT_DIREKT:
      return `${staticImageAssets}sellerInterface/saleItems/october24/recycle.png`

    default:
      return `${staticImageAssets}sellerInterface/saleItems/october24/fallback.png`
  }
}

export const calculateCustomerShare = (value, pricingModel, sale) => {
  const saleConfig = sale && sale.get('saleConfig')
  if (saleConfig) {
    const share = saleConfig.get('customerValueShare')
    return money.multiply(share)(value)
  }

  const currency = Map.isMap(value) ? value.get('currency') : value.currency
  const customerShareThreshold = currencyUtil.customerShareThreshold(currency)
  if (money.lte(value, customerShareThreshold)) return money.multiply(0.4)(value)

  // Old customerValueShare for expensive item used to be 0.9, now it is 0.7
  const share = pricingModel <= 4 ? 0.9 : 0.7
  const shareAboveThreshold = money.multiply(share)(money.subtract(value)(customerShareThreshold))
  const shareBelowThreshold = money.multiply(0.4)(customerShareThreshold)
  return money.add(shareAboveThreshold, shareBelowThreshold)
}

export const customerShareIncludingFee = ({ value, pricingModel, sale, adFee }) =>
  money.subtract(calculateCustomerShare(value, pricingModel, sale))(adFee)

export const calculateP2pShare = (share) => ({ value }) => money.multiply(share)(value)

const FLIXSTOCK_DEWRINKLE_IMAGE_PATH = 'flixstock-images/dewrinkle/'

export const selectPhotosForUse = ({ itemStatus, photos }) => {
  return selectPhotosByItemStatus({ itemStatus, photos }).filter(
    (photo) => !photo.url?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH)
  )
}
export const selectPhotosForUseSorted = ({ itemStatus, photos }) => {
  return selectPhotosByItemStatus({ itemStatus, photos }).sort(
    (a, b) => Number(a.type === 'defect') - Number(b.type === 'defect')
  )
}

export const selectPhotosForUseSeller = (item) => {
  return selectPhotosByItemStatusSeller(item).filter(
    (photo) => !photo.url?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH)
  )
}

export const selectImagesForUse = (images) => {
  return images.filter((url) => !url?.includes(FLIXSTOCK_DEWRINKLE_IMAGE_PATH))
}

const selectPhotosByItemStatus = ({ photos, itemStatus }) => {
  return photos?.map((photo) => photo.value) || [defaultPhoto(itemStatus)]
}

const selectPhotosByItemStatusSeller = (item) => {
  return item.photos?.map((photo) => photo.value) || []
}

export const selectImagesByItemStatus = (item) => {
  if (item.get('itemStatus') === ITEM_STATUS.REGISTRERAD)
    return List([defaultImage(ITEM_STATUS.REGISTRERAD)])
  return item.get('images') || List([defaultImage(item.get('itemStatus'))])
}

const WEEKEND_CAMPAIGN_CURATION = 'c3b4b6fd-57ee-4b92-8c02-b67eb9ce3272'

export const isWithinWeekendCampaignTimeRange = () =>
  DateTime.utc() > DateTime.fromISO('2024-05-31T00:00:00Z') &&
  DateTime.utc() < DateTime.fromISO('2024-06-03T00:00:00Z')

/**
 * @param {string[]} [itemFeaturedInIds] List of campaign curations ids an item is featured in
 */
export const featuredInWeekendCampaign = (itemFeaturedInIds) =>
  (itemFeaturedInIds?.includes(WEEKEND_CAMPAIGN_CURATION) && isWithinWeekendCampaignTimeRange()) ??
  false

const _getDurations = (freightAlt) => {
  const [min, max] = freightAlt.estimatedShipping.interval.split('/')
  const earliest = Duration.fromISO(min).as('days')
  const latest = Duration.fromISO(max).as('days')
  return { earliest, latest }
}

const _compare = (a, b) => {
  const aDurations = _getDurations(a)
  const bDurations = _getDurations(b)
  const startDaysDiff = aDurations.earliest - bDurations.earliest
  const endDaysDiff = aDurations.latest - bDurations.latest
  if (Math.abs(startDaysDiff) < 0.5) return endDaysDiff
  return startDaysDiff
}

const stableSort = (arr) =>
  arr
    .map((item, index) => ({ index, item }))
    .sort((a, b) => _compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item)

export const getFastestFreightAlternative = (freightAlternatives) => {
  const sorted = stableSort(freightAlternatives)
  return sorted[0]
}

export const getIntervalDatesForFreightAlternative = (freightAlternative) => {
  const [min, max] = freightAlternative.estimatedShipping.interval.split('/')
  return {
    earliest: DateTime.local().plus(Duration.fromISO(min)),
    latest: DateTime.local().plus(Duration.fromISO(max))
  }
}

// Transitional transformer before complete GraphQL migration
export const getLightBoxItemFromImmutableItem = (item) => {
  if (!item.get) return item
  return {
    objectId: item.get('objectId'),
    metadata: item.get('metadata').toJS(),
    metadata_de: item.get('metadata_de').toJS(),
    metadata_en: item.get('metadata_en').toJS(),
    metadata_nl: item.get('metadata_nl').toJS(),
    metadata_da: item.get('metadata_da').toJS(),
    metadata_pl: item.get('metadata_pl').toJS(),
    metadata_fi: item.get('metadata_fi').toJS(),
    metadata_fr: item.get('metadata_fr').toJS()
  }
}

export const evictUserBalanceCache = (currency) => {
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'getTotalBalanceForUser',
    args: {
      currency: currency
    }
  })
}
