import React, { forwardRef } from 'react'
import { ICONS, Icon } from '@sellpy/design-system-react-web'
import { useNavigate, Link } from 'react-router-dom'
import { searchUrls } from '@sellpy/commons'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '../../../../../hooks'
import { region } from '../../../../../../common/region/region'
import { SearchInputContainer, StyledInput, CancelButton, ClearButton } from '../Styles.jsx'
import { analyticsSearch } from '../../../../../../common/analytics/actions.js'
import { SearchButton } from './SearchButton.jsx'

const InputIconsDivider = () => {
  const theme = useTheme()

  return (
    <div
      style={{
        width: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          width: '1px',
          height: '24px',
          backgroundColor: `${theme.color.grey.shade6}`
        }}
      />
    </div>
  )
}

const SearchButtonWithLink = ({ query, isToRight }) => (
  <Link
    style={{ display: 'flex' }}
    onMouseDown={(event) => {
      event.preventDefault()
      analyticsSearch({
        searchTerm: query,
        searchSource: 'text'
      })
    }}
    to={
      query
        ? searchUrls.stringifySearchStateV2(region())({
            query: query
          })
        : '/search'
    }
  >
    <SearchButton isToRight={isToRight} />
  </Link>
)

export const SearchInput = forwardRef(({ isFocused, setIsFocused, query, autocomplete }, ref) => {
  const { isTablet } = useResponsive()
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation(['search', 'common'])

  const autocompleteInputProps = autocomplete.getInputProps({})

  const shouldShowClearButton = Boolean(query)
  const shouldShowRightSearchButton = Boolean(isTablet && isFocused && query)

  return (
    <SearchInputContainer isFocused={isFocused}>
      {isTablet && isFocused ? (
        <CancelButton
          onClick={() => {
            autocomplete.setIsOpen(false)
            autocomplete.setQuery('')
          }}
        >
          <Icon
            name={ICONS.ARROW_LEFT}
            style={{ fontSize: '24px', color: theme.color.grey.shade3 }}
          />
        </CancelButton>
      ) : (
        <SearchButtonWithLink query={query} isToRight={false} />
      )}
      <StyledInput
        {...autocompleteInputProps}
        autoFocus={isFocused}
        ref={ref}
        placeholder={t('common:header.searchPlaceholder')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            analyticsSearch({
              searchTerm: query,
              searchSource: 'text'
            })
            autocomplete.setIsOpen(false)
            navigate(
              query
                ? searchUrls.stringifySearchStateV2(region())({
                    query
                  })
                : '/search'
            )
          }
        }}
        onFocus={(event) => {
          setIsFocused(true)
          // eslint-disable-next-line no-unused-expressions
          autocompleteInputProps?.onFocus?.(event)
        }}
        onBlur={(event) => {
          setIsFocused(false)
          autocomplete.setIsOpen(false)
          // eslint-disable-next-line no-unused-expressions
          autocompleteInputProps?.onBlur?.(event)
        }}
      />
      {shouldShowClearButton && (
        <ClearButton
          onMouseDown={(event) => {
            autocomplete.setQuery('')
            setIsFocused(true)
            autocomplete.setIsOpen(true)
            // eslint-disable-next-line no-unused-expressions
            ref?.current?.focus()
            autocomplete.refresh()
            event.preventDefault()
          }}
          isLastButton={!shouldShowRightSearchButton}
        >
          <Icon
            name={ICONS.CLOSE_CIRCLE}
            style={{ fontSize: '24px', color: theme.color.grey.shade3 }}
          />
        </ClearButton>
      )}
      {shouldShowRightSearchButton && (
        <>
          {shouldShowClearButton && shouldShowRightSearchButton && <InputIconsDivider />}
          <SearchButtonWithLink query={query} isToRight={true} />
        </>
      )}
    </SearchInputContainer>
  )
})

SearchInput.displayName = 'SearchInput'
