import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, H5, Icon, ICONS, InfoBox, P, theme } from '@sellpy/design-system-react-web'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../common/user/selectors'
import { sellerFeaturesBlock } from './sellerFeaturesBlock.gql'

export const useIsSellerFeaturesBlocked = () => {
  const currentUser = useSelector(currentUserSelector)

  const { data, loading, refetch } = useQuery(sellerFeaturesBlock, {
    variables: { userId: currentUser },
    skip: !currentUser
  })

  return {
    isSellerFeaturesBlocked: data?.isUserBlockedFromSellingServices,
    loading,
    refetch
  }
}

const SellerFeaturesBlock = ({ onCtaClick }) => {
  const { t } = useTranslation('featureBlock')

  return (
    <Container>
      <Column gap={24}>
        <Icon
          name={ICONS.TAG_NONE}
          style={{ fontSize: 136 }}
          color={theme.colour.text.static.nr200}
        />
        <Column gap={8}>
          <H5 style={{ color: theme.colour.text.static.nr100 }} noMargin>
            {t('sellerFeatures.block.title')}
          </H5>
          <P style={{ textAlign: 'center', color: theme.colour.text.static.nr200 }} design='body1'>
            {t('sellerFeatures.block.info')}
          </P>
        </Column>
      </Column>
      <Column gap={16}>
        <InfoBox
          type={'neutral'}
          variant={'prominent'}
          title={t('sellerFeatures.howToUnblock.title')}
          text={t('sellerFeatures.howToUnblock.info')}
        />
        <Button fullWidth label={t('sellerFeatures.howToUnblock.cta')} onClick={onCtaClick} />
      </Column>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colour.bg.static.nr100};
  border: 1px solid ${({ theme }) => theme.colour.stroke.static.nr100.subtle};
  padding: 24px 16px;
  border-radius: ${({ theme }) => theme.radius.soft};
  align-items: center;
  gap: 24px;
  max-width: max(406px, 40%);
  justify-self: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};
`

export default SellerFeaturesBlock
