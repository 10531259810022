fragment payoutRowFragment on PayOut {
  objectId
  status
  value
  createdAt
  bankAccount {
    objectId
    IBAN
    BIC
    accountNumber
    clearingNumber
  }
}
