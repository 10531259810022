#import "./favoriteList.fragment.gql"
query getFavoritesInList($listId: ID!) {
  itemWishlists: getFavoritesInList(listId: $listId) {
    objectId
    active
    selected
    item {
      objectId
    }
    itemFavoriteLists {
      ... on ItemFavoriteList {
        objectId
        ...favoriteListFragment
      }
    }
  }
}
