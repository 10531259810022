#import "./claimForm.fragment.gql"
#import "./components/itemClaimHeader.fragment.gql"

query newClaimPageQuery(
  $itemId: ID!
  $orderId: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  itemClaims(where: { item: { have: { objectId: { equalTo: $itemId } } } }) {
    edges {
      node {
        objectId
        ...claimFormFragment
      }
    }
  }
  item(id: $itemId) {
    objectId
    ...itemClaimHeaderFragment
  }
  order(id: $orderId) {
    objectId
    dateDelivered
    payment {
      objectId
    }
  }
}
