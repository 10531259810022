#import "./claimForm.fragment.gql"
#import "./components/itemClaimHeader.fragment.gql"

query editClaimPageQuery(
  $itemId: ID!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  itemClaims(where: { item: { have: { objectId: { equalTo: $itemId } } } }) {
    edges {
      node {
        objectId
        status
        type
        bankDetails
        order {
          objectId
          dateDelivered
          payment {
            objectId
          }
        }
        item {
          objectId
          ...itemClaimHeaderFragment
        }
        ...claimFormFragment
      }
    }
  }
}
