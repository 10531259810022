#import "./pricing/PreparedSalePricing.fragment.gql"

fragment ChangePricingItemFragment on Item {
  ...PreparedSalePricingItemFragment
  objectId
  itemStatus
}

fragment ChangePricingMarketInventoryFragment on MarketInventory {
  ...PreparedSalePricingMarketInventoryFragment
  ...LockedPricingMarketInventoryFragment
  prepared
  active
}
