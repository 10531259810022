import { Map, Record, List, fromJS } from 'immutable'
import * as sellerDataActions from '../sellerData/actions'
import * as marketLandingActions from '../marketLanding/actions'
import { GET_PROCESSING_CARBON_FOOTPRINT_FOR_ITEMS } from '../itemCarbonFootprint/actions'
import * as actions from './actions'

const InitialState = Record({
  headerCategories: Map(),
  items: Map(),
  algoliaItems: Map(),
  similarItems: Map(),
  similarItemsWeights: Map(),
  similarBrands: Map(),
  loading: false,
  auctionLoading: false,
  marketOfferLoading: false,
  marketFirstInventorySuccess: false,
  marketFirstInventoryLoading: false,
  saleHistory: Map(),
  saleFutures: Map(),
  numberOfWishlists: Map(),
  whereWasItemSold: Map(),
  algoliaBrands: List(),
  algoliaTypes: List(),
  isBusinessSale: Map(),
  numberOfItemsShown: 0,
  numberOfItemsShownState: {},
  previouslyInCart: List(),
  expectedShippingDates: Map(),
  itemValues: Map(),
  itemPayments: Map(),
  priceEstimate: Map(),
  processingCarbonFootprints: Map()
})
const initialState = new InitialState()

export default function itemsReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)
  switch (action.type) {
    case actions.ITEM_LOADING:
      return state.set('loading', true)
    case actions.ITEM_ERROR:
    case actions.ITEM_SUCCESS: {
      return state.set('loading', false)
    }
    case actions.ALGOLIA_ITEMS_SUCCESS:
    case marketLandingActions.FETCH_SELLER_ITEMS_SUCCESS: {
      const items = action.hits
        // don't update the state if object is already fetched, in order to reduce unnecessary rendering
        // algolia is caching the queries anyhow https://github.com/algolia/algoliasearch-client-javascript#cache
        .filter((hit) => !state.hasIn(['algoliaItems', hit.objectID]))
        .reduce((items, item) => items.set(item.objectID, fromJS(item)), Map())
      return state.mergeIn(['algoliaItems'], items)
    }
    case actions.GET_ITEM_PRICE_ESTIMATE: {
      return state.mergeIn(['priceEstimate'], action.priceEstimate)
    }
    case actions.GET_SIMILAR_ITEMS_SUCCESS: {
      return state.mergeIn(['similarItems'], action.itemIds)
    }
    case actions.GET_SIMILAR_BRANDS_SUCCESS: {
      return state.mergeIn(['similarBrands'], action.brands)
    }

    case actions.GET_MARKET_OFFER_LOADING:
      return state.set('marketOfferLoading', true)
    case actions.GET_MARKET_OFFER_SUCCESS:
    case actions.GET_MARKET_OFFER_ERROR:
      return state.set('marketOfferLoading', false)

    case actions.GET_MARKET_FIRST_INVENTORY_LOADING:
      return state.set('marketFirstInventoryLoading', true)
    case actions.GET_MARKET_FIRST_INVENTORY_SUCCESS:
      return state
        .set('marketFirstInventoryLoading', false)
        .set('marketFirstInventorySuccess', true)
    case actions.GET_MARKET_FIRST_INVENTORY_ERROR:
      return state.set('marketFirstInventoryLoading', false)

    case actions.SALE_FUTURE_SUCCESS: {
      return state.update('saleFutures', (saleFutures) => saleFutures.merge(action.saleFuture))
    }
    case actions.SALE_HISTORY_SUCCESS: {
      return state.update('saleHistory', (saleHistory) => saleHistory.merge(action.saleHistory))
    }

    case sellerDataActions.EDIT_ITEM_SUCCESS: {
      return state.setIn(['items', action.item.get('objectId')], action.item)
    }
    case actions.WHERE_WAS_ITEM_SOLD_SUCCESS: {
      return state.setIn(
        ['whereWasItemSold', action.itemId],
        Map({ offerName: action.offerName, offerId: action.offerId })
      )
    }
    case actions.WHERE_WAS_ITEM_SOLD_ERROR: {
      return state.setIn(['whereWasItemSold', action.itemId], null)
    }
    case actions.FETCH_BRANDS: {
      return state.set('algoliaBrands', action.brands)
    }
    case actions.FETCH_TYPES: {
      return state.set('algoliaTypes', action.itemTypes)
    }
    case actions.IS_BUSINESS_SALES: {
      return state.mergeIn(['isBusinessSale'], action.isBusinessSalesMap)
    }
    case actions.FETCH_EXPECTED_SHIPPING_DATES: {
      return state.mergeIn(['expectedShippingDates'], action.dates)
    }
    case actions.ITEMS_PREVIOUSLY_CART: {
      return state.set('previouslyInCart', action.items)
    }
    case actions.ITEM_VALUES: {
      return state.update('itemValues', (itemValues) => itemValues.merge(action.itemValues))
    }
    case actions.ITEM_PAYMENTS: {
      return state.update('itemPayments', (itemPayments) => itemPayments.merge(action.itemPayments))
    }
    case GET_PROCESSING_CARBON_FOOTPRINT_FOR_ITEMS: {
      const { itemIds, processingCarbonFootprintForItems } = action.payload
      return state.updateIn(
        ['processingCarbonFootprints', itemIds.join(',')],
        () => processingCarbonFootprintForItems
      )
    }
  }
  return state
}

export const getIsBusinessSaleSelector = (state, items) =>
  items.reduce(
    (map, item) => map.set(item.get('objectId'), state.items.isBusinessSale.get(item.get('bag'))),
    Map()
  )
