fragment emailSuppressionFragment on EmailSuppression {
  objectId
  serviceProviderId
  suppressed
}

query fetchEmailSuppressions($encryptedEmail: String) {
  emailSuppressions: fetchEmailSuppressions(encryptedEmail: $encryptedEmail) {
    ...emailSuppressionFragment
  }
}