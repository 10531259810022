#import "./saleShare.fragment.gql"

query getSaleShareData(
  $saleId: ID!
  $userId: ID!
  $region: String!
  $completedAdsTransactionTypes: [String]!
  $isSV: Boolean!
  $isDE: Boolean!
  $isEN: Boolean!
  $isNL: Boolean!
  $isDA: Boolean!
  $isPL: Boolean!
  $isFI: Boolean!
  $isFR: Boolean!
  $isCS: Boolean!
) {
  reservedBalanceTransactions(
    where: {
      sale: { have: { objectId: { equalTo: $saleId } } }
      user: { have: { objectId: { equalTo: $userId } } }
      settledAt: { exists: false }
    }
    first: 5000
  ) {
    edges {
      node {
        ...SaleShareReservedBalanceTransactionFragment
      }
    }
  }
  completedAdsTransactions: balanceTransactions(
    where: {
      type: { in: $completedAdsTransactionTypes }
      sale: { have: { objectId: { equalTo: $saleId } } }
      user: { have: { objectId: { equalTo: $userId } } }
      region: { equalTo: $region }
    }
    first: 5000
    order: createdAt_DESC
  ) {
    edges {
      node {
        ...SaleShareBalanceTransactionFragment
      }
    }
  }
  salePayouts: balanceTransactions(
    where: {
      type: { equalTo: "salePayout" }
      sale: { have: { objectId: { equalTo: $saleId } } }
      user: { have: { objectId: { equalTo: $userId } } }
      region: { equalTo: $region }
    }
    order: createdAt_DESC
    first: 1000
  ) {
    edges {
      node {
        ...SaleShareBalanceTransactionFragment
      }
    }
  }
  sale: bag(id: $saleId) {
    status
  }
}
